import React, { useEffect, useState } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import BasicTable from "@components/Tables/BasicTable";
import electoral from "@services/ElectoralServices";
import Swal from "sweetalert2";
import FilterResponsable from "@components/Electoral/FilterResponsable";
import AttendanceCard from "@components/Attendance/AttendanceCard";
import { numberWithCommas } from '@utils/Utilities';
import { roundTo } from "@components/Maps/Auxiliars/MathFunctions";
import { isEmptyOrNullObject } from '@utils/validations';

import { CardContent, Container, Grid, Card } from "@mui/material";

const ResponsablesElectoral = () => {
  //variables
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [sortedData, setSortedData] = useState([]);
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
    sorted:[{id: "cat_secciones.idPerfil", value: "asc"}]

  });
  const [statsData, setStatsData] = useState({
    page: 0,
    pageSize: 99999999999999,
    filtered: [],
  });
  const [loading, setLoading] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [dataCard,setDataCard] = useState({
    "TotalMeta": 0,
    "TotalAvance": 0,
    "PorcentajeAvance": "0.22"
  })
  const columns = [
    { id: "PerfilAmbito", label: "Perfil", columnAction: false, width: 150,  orderBy: "PerfilAmbito" },
    { id: "NombreCompleto", label: "Nombre", columnAction: false, width: 200, orderBy:"NombreCompleto" },
    { id: "Celular", label: "Celular", columnAction: false, width: 200, orderBy:"Celular" },
    { id: "CompromisosDirectos", label: "Compromisos Directos", columnAction: false,  width: 20, orderBy:"CompromisosDirectos" },
    { id: "CompromisosUnicosDirectos", label: "Compromisos Unicos Directos", columnAction: false,  width: 20, orderBy:"CompromisosUnicosDirectos" },
    { id: "UsuariosDirectos", label: "Usuarios Directos", columnAction: false, width: 20, orderBy:"UsuariosDirectos" },
    { id: "CompromisosEnRed", label: "Compromisos En Red", columnAction: false,  width: 20, orderBy: "CompromisosEnRed"},
    { id: "CompromisosUnicosEnRed", label: "Compromisos Unicos En Red", columnAction: false,  width: 20, orderBy:"CompromisosUnicosEnRed" },
    { id: "UsuariosEnRed", label: "Usuarios En Red", columnAction: false, width: 20, orderBy: "UsuariosEnRed"},
  ];

  //effets

  useEffect(() => {
    getData();
  }, [tableData]);



  useEffect(() => {
    if (sortedData.length > 0) {
      let params = {
        ...tableData,
        sorted: sortedData
      };

      setTableData(params)
    }
    // eslint-disable-next-line
  }, [sortedData]);

  // funciones

  const getData = () => {
    if (loading) {
      return true;
    }
    setLoading(true);
    electoral
      .getResponsables(tableData)
      .then((res) => {
        if (res.results) {
          setData(res.response.data.map(item => {
            return {
              ...item,
              CompromisosDirectos:item.CompromisosDirectos !== 0 ? numberWithCommas(item.CompromisosDirectos) : "0",
              CompromisosUnicosDirectos: item.CompromisosUnicosDirectos !== 0 ? numberWithCommas(item.CompromisosUnicosDirectos) : "0",
              UsuariosDirectos: item.UsuariosDirectos !== 0 ? numberWithCommas(item.UsuariosDirectos) : "0",
              CompromisosEnRed: item.CompromisosEnRed !== 0 ? numberWithCommas(item.CompromisosEnRed) : "0",
              CompromisosUnicosEnRed: item.CompromisosUnicosEnRed !== 0 ? numberWithCommas(item.CompromisosUnicosEnRed) : "0",
              UsuariosEnRed: item.UsuariosEnRed !== 0 ? numberWithCommas(item.UsuariosEnRed) : "0"
            }
          }));
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });

      setLoadingStats(true);
      electoral
      .getStatsResponsables(statsData)
      .then((res) => {
        if (res.results) {
          setDataCard(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoadingStats(false);
      });


  };

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const handleChangeFilter = (type) => {
    const {
      Region,
      Responsabilidad,
      DF,
      MunicipioDL,
      Celular,
      NombreCompleto,
    } = type;

    let filtered = [];
    let filtro = [];

    if (Region > 0) {
      filtered = [
        ...filtered,
        { id: "cat_secciones.idRegion", filter: "=", value: Region },
      ];

      filtro = [
        ...filtro,
        {
          id: "cat_municipios_reportes.idRegion",
          filter: "=",
          value: Region,
        },
      ];
    }
    if (DF > 0) {
      filtered = [
        ...filtered,
        { id: "cat_secciones.idRegion", filter: "=", value: DF },
      ];

      filtro = [
        ...filtro,
        {
          id: "cat_municipios_reportes.idRegion",
          filter: "=",
          value: DF,
        },
      ];
    }
    if (MunicipioDL > 0) {
      filtered = [
        ...filtered,
        { id: "cat_secciones.idMunicipioReportes", filter: "=", value: MunicipioDL },
      ];

      filtro = [
        ...filtro,
        {
          id: "cat_municipios_reportes.id",
          filter: "=",
          value: MunicipioDL,
        },
      ];
    }
    if (Celular.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "cat_secciones.Celular",
          filter: "=",
          value: Celular,
        },
      ];
    }
    if (NombreCompleto.length > 0) {
      filtered = [
        ...filtered,
        {
          id: "cat_secciones.NombreCompleto",
          filter: "LIKE",
          value: `%${NombreCompleto.toUpperCase().replace(/ /g, "%")}%`,
        },
      ];
    }
    if (Responsabilidad > 0) {
      filtered = [
        ...filtered,
        {
          id: "cat_secciones.idPerfil",
          filter: "=",
          value: Responsabilidad,
        },
      ];

   filtro = [
        ...filtro,
        {
          id: "estad_meta_electoral.idPerfil",
          filter: "=",
          value: Responsabilidad,
        },
      ];
    }

    setTableData({
      ...tableData,
      page:0,
      filtered: filtered,
    });

    setStatsData({
      ...statsData,
      filtered:filtro
    })

    
  };



  const handleSorted = (dataSorted) => {
    const fields = {
      NombreCompleto:
        "cat_secciones.NombreCompleto",
      Celular:"cat_secciones.Celular",
      PerfilAmbito: "cat_secciones.idPerfil",
      CompromisosDirectos: "cat_secciones.CompromisosDirectos",
      CompromisosUnicosDirectos: "cat_secciones.CompromisosUnicosDirectos",
      UsuariosDirectos: "cat_secciones.UsuariosDirectos",
      CompromisosEnRed: "cat_secciones.CompromisosEnRed",
      CompromisosUnicosEnRed: "cat_secciones.CompromisosUnicosEnRed",
      UsuariosEnRed: "cat_secciones.UsuariosEnRed",
    };

    let sorted =  [];

    if (!isEmptyOrNullObject(dataSorted)) {
      sorted.push({
        id: fields[dataSorted.orderBy],
        value: dataSorted.order
      });
    }

    return sorted;
  }

  const handleRequestSort = (sortData) => {
    if (total > 0) setSortedData(handleSorted(sortData))
  }

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title={"Responsables"} />
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card>
            <CardContent>
            <FilterResponsable onChangeFilter={(e)=>handleChangeFilter(e)} df={false}/>
            </CardContent>
        </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
        <AttendanceCard
              data={{
                contentData: String(roundTo(dataCard.PorcentajeAvance,2)),
                leftFooterData: String(numberWithCommas(total)),
                rightFooterData: String(numberWithCommas(dataCard.TotalMeta)),
              }}
              config={{
                leftFooterTitle: "Avance",
                rightFooterTitle: "Meta",
              }}
              loading ={loadingStats}
              titlecontent={"Responsables"}
            />
        </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardContent>
                <BasicTable
                  rows={data}
                  hcolumns={columns}
                  pageProp={tableData.page}
                  pageSize={tableData.pageSize}
                  total={total}
                  handleChangePagination={handleChangePagination}
                  isLoading={loading}
                  stickyHeader={true}
                  handleManualSort={handleRequestSort}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
};
export default ResponsablesElectoral;
