import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import UsersSearch from "@components/Users/UsersSearch";
// import UserPhone from "@components/Users/UpdatePhone";
import SupportService from "@services/SupportServices";

const UpdatePhone = () => {
  const navigate = useNavigate();

  const [isLoadigForm, setIsLoadingForm] = useState(false);
  const [isSuccessForm] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(false);
  const [id, setId] = useState("");
  const [nombreCompleto, setNombreCompleto] = useState("");

  return (
    <AdminLayout>
      <LoadingForm
        isLoading={isLoadigForm}
        success={isSuccessForm}
        isOpen={openLinearProgress}
        setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        loadinMessage={"Cargando..."}
      />
      <Container maxWidth="false">
        <ActionHeader
          title="Cambio de Celular"
          isAction={true}
          titleButton="Regresarr"
          iconButton="arrow_back"
          handleclick={() => navigate(-1)}
        />

        <Card className="card-primary">
          <CardContent>
            <Box sx={{ mt: 1, mb: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <UsersSearch
                    idUsuario={setId}
                    nombreCompleto={setNombreCompleto}
                    loading={setIsLoadingForm}
                    openLineal={setOpenLinearProgress}
                    API={SupportService.getUser}
                  />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                  <Grid
                    style={{
                      height: "50px",
                      textAlign: "center",
                      marginBottom: "10px",
                      fontStyle: "italic",
                      borderBottom: "2px solid #000",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{
                        visibility: nombreCompleto ? "visible" : "hidden",
                      }}
                    >
                      {nombreCompleto}
                    </Typography>
                  </Grid>
                  <Card variant="outlined">
                    <CardContent>
                      {/* <UserPhone idUsuario={id} /> */}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default UpdatePhone;
