import React, { useState, useEffect, useCallback } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import { Container } from "@mui/material";
import CartographyService from "@services/MapServices/NationalServices";
import Swal from "sweetalert2";
import Map from "@components/Maps/CustomComponents/National";
import { setVars } from "@utils/global";
import Filter from "@components/MapsModules/National/FilterTabs";
import CatalogService from "@services/CatalogServices";
const elements = {
  manzana: [
    {
      title: "Manzana: ",
      name: "Manzana",
      type: "text",
    },
    {
      title: "Prioridad: ",
      name: "PrioridadLabel",
      type: "text",
    },
  ],
  seccion: [
    {
      title: "Sección: ",
      name: "seccion",
      type: "text",
    },
    {
      title: "Municipio: ",
      name: "Municipio",
      type: "text",
    },
    {
      title: "DLOC: ",
      name: "DLOC",
      type: "text",
    },
    {
      title: "DFED: ",
      name: "DFED",
      type: "text",
    },
    {
      title: "Meta Simpatizantes: ",
      name: "meta",
      type: "int"
    },
    {
      title: "Avance Simpatizantes: ",
      name: "avance",
      type: "int"
    },
    {
      title: "% Avance: ",
      name: "porcentaje",
      type: "text"
    }
  ],
  poligono: [
    {
      title: "Polígono: ",
      name: "poligono",
      type: "text",
    }
  ],
  municipio: [
    {
      title: "Municipio: ",
      name: "municipio",
      type: "text"
    },
    {
      title: "Meta Simpatizantes: ",
      name: "meta",
      type: "int"
    },
    {
      title: "Avance Simpatizantes: ",
      name: "avance",
      type: "int"
    },
    {
      title: "% Avance: ",
      name: "porcentaje",
      type: "text"
    },
  ],
  region: [
    {
      title: "Region: ",
      name: "region",
      type: "text"
    }
  ],
  entidad: [
    {
      title: "Entidad: ",
      name: "entidad",
      type: "text"
    },
    {
      title: "Meta Simpatizantes: ",
      name: "meta",
      type: "int"
    },
    {
      title: "Avance Simpatizantes: ",
      name: "avance",
      type: "int"
    },
    {
      title: "% Avance: ",
      name: "porcentaje",
      type: "text"
    },
  ],
  dfed: [
    {
      title: "DFED: ",
      name: "dfed",
      type: "text",
    },
    {
      title: "Meta Simpatizantes: ",
      name: "meta",
      type: "int"
    },
    {
      title: "Avance Simpatizantes: ",
      name: "avance",
      type: "int"
    },
    {
      title: "% Avance: ",
      name: "porcentaje",
      type: "text"
    },
  ],
  dloc: [
    {
      title: "DLOC: ",
      name: "dloc",
      type: "text",
    },
    {
      title: "Meta Simpatizantes: ",
      name: "meta",
      type: "int"
    },
    {
      title: "Avance Simpatizantes: ",
      name: "avance",
      type: "int"
    },
    {
      title: "% Avance: ",
      name: "porcentaje",
      type: "text"
    },
  ],
};
const Maps = () => {
  const [polygons, setPolygons] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [polygonsBlocks, setPolygonsBlocks] = useState({
    type: "FeatureCollection",
    features: [],
  });
  
  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);
  const [valuesInfoGeneral] = useState(null);
  const [catalogs, setCatalogs] = useState({
    entidades: [],
    municipios: [],
  });
  const [shpInfoGeneral, setShpInfoGeneral] =  useState('entidad');

  const getEmptyMap = () => {
    setPolygons({
      type: "FeatureCollection",
      features: [],
    });
  };

  const handleFilterExect = (filter=[],value=0) => {
    if(filter.length===0){
      return true;
    }
    let shp = 'entidad';
    let idEntidad = 0;
    const comparative_word = filter[0]['id'].split('.')[1];
    switch(comparative_word){
      case 'idEntidad':
      default:
        const found =  filter.find(objeto => objeto.id === 'tb.idEntidad').value;
        idEntidad = found;
        break;
    }
    // Checar nivel de capa
    const comparative_word_shp = filter.slice(-1)[0]['id'].split('.')[1];
    switch(comparative_word_shp){
      case 'idMunicipio':
        shp = 'seccion';
        break;
        case 'idDF':
          shp = 'seccion';
          break;
          case 'idDL':
            shp = 'seccion';
            break;
      case 'Seccion':
        shp = 'seccion';
        break;
      case 'idEntidad':
      default:
          shp = 'municipio';
    }
    if(idEntidad ===0){
      shp = 'entidad';
    }
    else if(value===0){
      shp = 'municipio';
    }
    else if(value===1){
      shp = 'dfed';
    }
    else if(value===2){
      shp = 'dloc';
    }
    setPolygons(null);
    setPolygonsBlocks(null);
    setShpInfoGeneral(shp);
    CartographyService.getShapes({color:1, page:0, pageSize:10, filtered:filter, shp ,idEntidad})
    .then((res) => {
      if (res.results) {
        setPolygons(res.response.data);
      } else {
        Swal.fire({ title: res.message, icon: "warning" });
      }
    })
    .catch((e) => {
      Swal.fire({ title: e.message, icon: "warning" });
    });
  };

  const loadCatalogs = useCallback(() => {
    let catalogsParams = [{ id: "entidades", getAll:false }];
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          if (res.response.errors.length > 0) {
            Swal.fire({
              title:
                "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
              icon: "warning",
            });
          }

          const catalogsResponse = res.response.catalogs;
          setCatalogs((prevState) => {
            return {
              ...prevState,
                entidades: catalogsResponse.entidades
                ? [{value: 0, label: "TODAS"}].concat(catalogsResponse.entidades)
                : [],
            };
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatalogs(false);
      });
  }, []);
  useEffect(() => {
    setVars("filterSavedMap", {
      idEntidad: "",
      idMunicipio: "",
      idDF: "",
      idDL: "",
      Seccion: ""
    })
    getEmptyMap();
    loadCatalogs();
    handleFilterExect([{filter: "IN",id:"tb.idEntidad",value: 0}]);
    // eslint-disable-next-line
  }, []);
  const FilterComponent = (
    <Filter
      catalogs={catalogs}
      loadingCatalogs={isLoadingCatalogs}
      handleFilter={handleFilterExect}
    />
  );

  const handleClickViewDetail = (e) => {
    //mandar a la capa inferior con el parametro solicitado
    let shp = 'entidad';
    const idEntidad = e.idEntidad;
    let filter = [];
    switch(e.shp){
      case 'entidad':
        shp = 'municipio';
        filter.push({filter: "IN",id:"tb.idEntidad",value: idEntidad});
        break;
      case 'municipio':
      default:
          shp = 'seccion';
          filter.push({filter: "IN",id:"tb.idEntidad",value: idEntidad});
          filter.push({filter: "IN",id:"tb.idMunicipio",value: e.idMunicipio});
          break;
    }
    setPolygons(null);
    setShpInfoGeneral(shp);
    CartographyService.getShapes({color:1, page:0, pageSize:10, filtered:filter, shp ,idEntidad})
    .then((res) => {
      if (res.results) {
        setPolygons(res.response.data);
      } else {
        Swal.fire({ title: res.message, icon: "warning" });
      }
    })
    .catch((e) => {
      Swal.fire({ title: e.message, icon: "warning" });
    });
  }
  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <Map
          data={polygons}
          sub_data={polygonsBlocks}
          CardComponentFlag={false}
          CardComponent={
            <></>
          }
          TitleCardComponent={""}
          CardInfoFlag={true}
          CardInfoInitialValues={{
            title: "INFORMACIÓN",
            titleButton: "Ver Detalle",
            elements: elements[shpInfoGeneral],
          }}
          handleClickViewDetailEvent={handleClickViewDetail}
          FilterComponentFlag={true}
          FilterComponent={FilterComponent}
          flagInfoGeneral={false}
          valuesInfoGeneral={valuesInfoGeneral}
          initialValuesInfoGeneral={{
            title: "RESUMEN",
            elements: [
              {
                title: "center",
                name: shpInfoGeneral,
                color: "#000",
                type: "text",
              }
            ],
          }}
        ></Map>
      </Container>
    </AdminLayout>
  );
};
export default Maps;
