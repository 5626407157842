const CompromisoAddInterface = {
  idCompromisoPropietario: "",
  idUsuarioPropietario: "",
  idUsuarioResponsable: "",
  idPartido: 1,
  INE: "",
  VigenciaINE: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  idSexo: "",
  idEstadoNacimiento: "",
  CalleVive: "",
  TipoVialidad: "C",
  NumExtVive: "",
  NumIntVive: "",
  idMunicipioVive: "",
  idLocalidadVive: "",
  ColoniaVive: "",
  CPVive: "",
  SeccionVota: "",
  Celular: "",
  Telefono: "",
  TelMensajes: "",
  Correo: "",
  Facebook: "",
  Instagram: "",
  Twitter: "",
  TikTok: "",
  EsVoluntario: 1,
  idVoluntario: "",
  TieneAuto: "",
  TieneExperiencia: "",
  AceptaCuidarOtra: "",
  Segmentos: [],
  idMedioContacto: "",
};

const CompromisoAddInterfaceRegister = {
  idUsuario: "",
  idEstado: 0,
  DeviceID: "",
  idCompromisoPropietario: "",
  idUsuarioPropietario: "",
  idUsuarioResponsable: "",
  idPartido: 1,
  INE: "",
  VigenciaINE: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  idSexo: "",
  idEstadoNacimiento: "",
  CalleVive: "",
  TipoVialidad: "C",
  NumExtVive: "",
  NumIntVive: "",
  idMunicipioVive: "",
  idLocalidadVive: "",
  ColoniaVive: "",
  CPVive: "",
  SeccionVota: "",
  Celular: "",
  Telefono: "",
  TelMensajes: "",
  Correo: "",
  Facebook: "",
  Instagram: "",
  Twitter: "",
  TikTok: "",
  EsVoluntario: 1,
  idVoluntario: "",
  TieneAuto: "",
  TieneExperiencia: "",
  AceptaCuidarOtra: "",
  Segmentos: [],
  idMedioContacto: "",
  AceptaTerminos: false,
};

const CompromisoAddExtranjeroInterface = {
  idCompromisoPropietario: "",
  idUsuarioPropietario: "",
  idUsuarioResponsable: "",
  idPartido: 1,
  INE: "",
  VigenciaINE: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  idSexo: "",
  idPais: "",
  idEstadoExtranjero: "",
  State: "",
  Line1: "",
  Line2: "",
  ZipCode: "",
  idEstadoNacimiento: "",
  CalleVive: "",
  TipoVialidad: "C",
  NumExtVive: "",
  NumIntVive: "",
  idMunicipioVive: "",
  idLocalidadVive: "",
  ColoniaVive: "",
  CPVive: "",
  Celular: "",
  Telefono: "",
  TelMensajes: "",
  Correo: "",
  Facebook: "",
  Instagram: "",
  Twitter: "",
  TikTok: "",
};

const CompromisoAddInterfacePublic = {
  isUsuario: 0,
  Password: "",
  Confirm: "",
  PIN: "",
  idUsuarioPropietario: "",
  idPartido: 1,
  INE: "",
  VigenciaINE: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  idSexo: "",
  idEstadoNacimiento: "",
  CalleVive: "",
  TipoVialidad: "C",
  NumExtVive: "",
  NumIntVive: "",
  idMunicipioVive: "",
  idLocalidadVive: "",
  ColoniaVive: "",
  CPVive: "",
  SeccionVota: "",
  Celular: "",
  Telefono: "",
  TelMensajes: "",
  Correo: "",
  Facebook: "",
  Instagram: "",
  Twitter: "",
  TikTok: "",
  EsVoluntario: 1,
  idVoluntario: 1,
  TieneAuto: "",
  TieneExperiencia: "",
  AceptaCuidarOtra: "",
  Segmentos: [],
  idMedioContacto: 2,
  idEvento: "",
  token: "",
};

const CompromisoAddInterfacePublicRegister = {
  isUsuario: 0,
  idEstado: 0,
  idAfiliacion: 1,
  idUsuario: "",
  DeviceID: "",
  Password: "",
  Confirm: "",
  PIN: "",
  idUsuarioPropietario: "",
  idPartido: 1,
  INE: "",
  VigenciaINE: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  idSexo: "",
  idEstadoNacimiento: "",
  CalleVive: "",
  TipoVialidad: "C",
  NumExtVive: "",
  NumIntVive: "",
  idMunicipioVive: "",
  idLocalidadVive: "",
  ColoniaVive: "",
  CPVive: "",
  SeccionVota: "",
  Celular: "",
  Telefono: "",
  TelMensajes: "",
  Correo: "",
  Facebook: "",
  Instagram: "",
  Twitter: "",
  TikTok: "",
  EsVoluntario: 1,
  idVoluntario: 1,
  TieneAuto: "",
  TieneExperiencia: "",
  AceptaCuidarOtra: "",
  Segmentos: [],
  idEstructuraSocial: [],
  idMedioContacto: 2,
  idEvento: "",
  NombreOSC: null,
  AceptaTerminos: false,
  token: "",
};

const AmbitAddInterface = {
  idTipoAmbito: "",
  idAmbito: "",
  idPerfil: "",
  idRegion: "",
  idDL: "",
  idDF: "",
  idMunicipio: "",
  idMunicipio_dl: "",
  idPoligono: "",
  idSeccion: "",
  idManzana: "",
  idCasilla: "",
};

const CompromisoFilterHistory = {
  celular: "",
  nombre: "",
  seccion: "",
};

const CompromisoFilterHistoryExt = {
  idPais: "",
  nombre: "",
  celular: "",
};

const AttendanceFilterInterface = {
  celular: "",
  nombre: "",
};

const AttendanceFoundFilterInterface = {
  celular: "",
  nombre: "",
};

const AttendanceReportFilterInterface = {
  idRegion: "",
  idMunicipio: "",
};

export {
  CompromisoAddInterface,
  AmbitAddInterface,
  CompromisoFilterHistory,
  CompromisoFilterHistoryExt,
  AttendanceFilterInterface,
  AttendanceFoundFilterInterface,
  AttendanceReportFilterInterface,
  CompromisoAddInterfacePublic,
  CompromisoAddInterfaceRegister,
  CompromisoAddInterfacePublicRegister,
  CompromisoAddExtranjeroInterface,
};
