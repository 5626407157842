import React, { useEffect, useState } from "react";

import {
  Button,
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Link,
  Icon,
} from "@mui/material";
import AdminLayout from "@components/MainPage/AdminLayout";
import { useNavigate } from "react-router-dom";
import { getVars } from "@utils/global";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CardIconColor from "@components/Card/CardIconColor";
import { URL_PUBLICA_FRONT } from "../../utils/global";
import UserServices from "@services/UserServices";
import Swal from "sweetalert2";

const Welcome = () => {
  const navigate = useNavigate();
  const { user } = getVars("Token");
  const [shortUrl, setShortUrl] = useState("");
  const [totalesAvance, setTotalesAvance] = useState({
    TotalWeb: 0,
    TotalGuest: 0,
  });
  const [fechaFin, setFechaFin] = useState();
  const [loading, setLoading] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // ... [Código previo]

    if (Object.keys(user).length > 0) {
      setShortUrl(`${URL_PUBLICA_FRONT}invitado/${user.idEstado}/${user.UUID}`);
    }

    getAvances();

    if (fechaFin) {
      // Calcula el tiempo restante
      const targetDate = new Date(fechaFin); // Cambia a tu fecha objetivo
      const now = new Date();

      const timeDiff = targetDate - now;

      if (timeDiff > 0) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setTimeRemaining({ days, hours, minutes, seconds });

        // Actualiza la cuenta regresiva cada segundo
        const countdownInterval = setInterval(() => {
          if (timeDiff > 0) {
            const updatedDiff = targetDate - new Date();
            const updatedDays = Math.floor(updatedDiff / (1000 * 60 * 60 * 24));
            const updatedHours = Math.floor(
              (updatedDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const updatedMinutes = Math.floor(
              (updatedDiff % (1000 * 60 * 60)) / (1000 * 60)
            );
            const updatedSeconds = Math.floor(
              (updatedDiff % (1000 * 60)) / 1000
            );

            setTimeRemaining({
              days: updatedDays,
              hours: updatedHours,
              minutes: updatedMinutes,
              seconds: updatedSeconds,
            });
          } else {
            clearInterval(countdownInterval);
          }
        }, 1000);
      }
    }
  }, [fechaFin]);

/*   useEffect(() => {
    if (Object.keys(user).length > 0) {
      setShortUrl(`${URL_PUBLICA_FRONT}invitado/${user.UUID}`);
    }
  }, []); */



  const getAvances = () => {
    UserServices.getAvanceCompromisos({})
      .then((res) => {
        if (res.results) {
          setTotalesAvance(res.response.data);
          setFechaFin(res.response.FechaFin);
        } else {
          setTotalesAvance({
            TotalWeb: 0,
            TotalGuest: 0,
          });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => setLoading(false));
  };

  const handleLabelClick = (labelContent) => {
    const tempInput = document.createElement("input");
    tempInput.value = labelContent;
    document.body.appendChild(tempInput);
    tempInput.select();

    document.execCommand("copy");

    document.body.removeChild(tempInput);
  };

  return (
    <AdminLayout>
      <Container component="main" /* maxWidth="lg" */>
        <Grid2
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
        >
          <Grid2 item lg={12} xs={12} md={12}>
            <Typography variant="h5" fontWeight={700}>
              {`¡Hola! ${user.Nombre} ${user.Paterno} ${user.Materno}`}
            </Typography>
          </Grid2>
          <Grid2 item lg={4} xs={12} md={12}>
            <CardIconColor
              title="Invitados"
              total={totalesAvance.TotalGuest}
              color="info"
              icon={"schema"}
              sx={{ height: "215px" }}
              loading={loading}
            />
          </Grid2>
          <Grid2 item lg={4} xs={12} md={12}>
            <CardIconColor
              title="Compromisos"
              total={totalesAvance.TotalWeb}
              color="success"
              icon={"schema"}
              sx={{ height: "215px" }}
              loading={loading}
            />
          </Grid2>
          <Grid2 item lg={4} xs={12} md={12}>
            <CardIconColor
              title={`${timeRemaining.hours} horas, ${timeRemaining.minutes} minutos, ${timeRemaining.seconds} segundos`}
              total={`${timeRemaining.days} días`}
              color="warning"
              icon={"schema"}
              sx={{ height: "215px" }}
            />
          </Grid2>
        </Grid2>
        <br />
        <Grid2
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
        >
          <Grid2 item lg={6} xs={12} md={12}>
            <Card sx={{ minWidth: 275, borderRadius: "30px" }}>
              <CardContent noWrap sx={{ textAlign: "center" }}>
                <Typography
                  sx={{ fontSize: 15 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Link de Invitación a mi red
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  size="large"
                  target="_blank"
                  component={Link}
                  href={shortUrl}
                >
                  {shortUrl}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  variant="contained"
                  size="small"
                  endIcon={<Icon>copy_all</Icon>}
                  onClick={() => handleLabelClick(shortUrl)}
                >
                  Copiar
                </Button>
              </CardActions>
            </Card>
          </Grid2>
          <Grid2 item lg={6} xs={12} md={12}>
            <Card
              onClick={() => navigate("/mi-perfil")}
              sx={{
                minWidth: 275,
                borderRadius: "30px",
                height: 155,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardContent sx={{ textAlign: "center" }}>
                <Grid2 container spacing={2}>
                  <Grid2 item lg={2} xs={2} md={2}>
                    <Icon>manage_accounts</Icon>
                  </Grid2>
                  <Grid2 item lg={10} xs={10} md={10}>
                    <Typography sx={{ fontSize: 20 }} color="darkblue">
                      Configuración
                    </Typography>
                  </Grid2>
                </Grid2>
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
      </Container>
    </AdminLayout>
  );
};

export default React.memo(Welcome);
