import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Card, Stack, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

import AdminLayout from "@components/MainPage/AdminLayout";
import { OPT_REGISTERS } from "@data/constants/Compromisos";

const ItemCard = ({ icon, selected, handleSelected }) => (
  <Box
    className="card-primary"
    component={Card}
    height={"176px"}
    width={"176px"}
    borderRadius={4}
    display="flex"
    alignItems="center"
    justifyContent="center"
    border={selected && `solid 2px ${blue[900]}`}
    onClick={handleSelected}
    sx={{ cursor: "pointer" }}
  >
    <Box component={"img"} src={icon} width={100} alt="pais" />
  </Box>
);

const CompromisosTypeRegister = () => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState(0);

  const handleSelected = (item) => {
    const { id } = item;
    setSelected(id);
  };

  const handleTypeRegister = () => {
    const routeMapping = {
      1: "/compromisos-registro",
      2: "/compromisos-ext-registro",
    };

    const selectedRoute = routeMapping[selected];
    if (selectedRoute) {
      navigate(selectedRoute);
    }
  };

  return (
    <AdminLayout>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems="center"
        justifyContent="center"
        height={"80vh"}
      >
        <Box>
          <Typography
            fontWeight={600}
            fontSize={24}
            sx={{ color: blue[900] }}
            marginTop={6}
            textAlign={"center"}
          >
            CAPTURA DE COMPROMISOS
          </Typography>
          <Typography
            variant="paragraph"
            textAlign={"center"}
            marginBottom={4}
            component={"p"}
          >
            Selecciona el tipo de compromisos que deseas capturar.
          </Typography>
          <Box
            display={"flex"}
            gap={5}
            sx={{ flexDirection: { xs: "column", sm: "row", md: "row" } }}
          >
            {OPT_REGISTERS.map((item, idx) => {
              return (
                <Box
                  key={idx}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <ItemCard
                    icon={item.icon}
                    selected={selected === item.id}
                    handleSelected={() => handleSelected(item)}
                  />
                  <Typography marginTop={2} fontWeight={600} fontSize={16}>
                    {item.title}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Stack
            direction="row"
            justifyContent={"end"}
            marginTop={6}
            marginBottom={1}
          >
            <Button
              variant="contained"
              color="primaryDark"
              onClick={handleTypeRegister}
              disabled={selected === 0}
            >
              Continuar
            </Button>
          </Stack>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default CompromisosTypeRegister;
