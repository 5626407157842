import ApiExec, { downloadFile, ApiExecPublic } from "@utils/ApiExec";
import { encryptVars, DEBUG } from "@utils/global";
import { handleAttrs } from "@utils/Utilities";

class CompromisoServices {
  getCaptureHistory = (params) => {
    return ApiExec(params, "compromisos/get-my-records", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getFindCommitments = (id) => {
    const _id = DEBUG ? id : encryptVars(id);
    return ApiExec({}, `compromisos/find-my-record/${_id}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getListVerification = (params) => {
    return ApiExec(params, "compromisos/get-list-verification", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getAttendanceReport = (params) => {
    return ApiExec(params, "compromisos/get-attendance-report", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getList = (params) => {
    return ApiExec(params, "compromisos/get-list", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getListForeign = (params) => {
    return ApiExec(params, "compromisos/get-list-foreign", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getSummary = (params) => {
    return ApiExec(params, "compromisos/get-summary", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getSummaryForeign = (params) => {
    return ApiExec(params, "compromisos/get-summary-foreign", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getChart = (params) => {
    return ApiExec(params, "compromisos/get-chart", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getChartForeign = (params) => {
    return ApiExec(params, "compromisos/get-chart-foreign", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  createCompromiso = (params) => {
    return ApiExec(handleAttrs(params), `compromisos/create`, "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  createCompromisoExtranjero = (params) => {
    return ApiExec(handleAttrs(params), `compromisos/create-foreign`, "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  createAttendance = (params) => {
    return ApiExec(params, `compromisos/take-attendance`, "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  updateCommitments = (params) => {
    return ApiExec(handleAttrs(params), "compromisos/update", "PUT")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  exportAttendanceReport = (params) => {
    return downloadFile({
      api: "compromisos/export-attendance-report",
      method: "POST",
      data: params,
    })
      .then((res) => res)
      .catch((e) => e);
  };

  getUserCommitments = (params) => {
    return ApiExec(params, `compromisos/get-user-commitments`, "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getResponsibles = (params) => {
    return ApiExec(params, "compromisos/get-responsibles", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  createCompromisoPublic = (params) => {
    return ApiExecPublic(params, `app/compromisos/create-invito`, "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  getMyForeignRecords = (params) => {
    return ApiExec(params, "compromisos/get-my-foreign-records", "POST")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  findMyForeignRecords = (id) => {
    const _id = DEBUG ? id : encryptVars(id);
    return ApiExec({}, `compromisos/find-my-foreign-record/${_id}`, "GET")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };

  updateForeign = (params) => {
    return ApiExec(handleAttrs(params), `compromisos/update-foreign`, "PUT")
      .then((res) => res)
      .then((res) => res)
      .catch((error) => error);
  };
}

const compromiso = new CompromisoServices();
export default compromiso;
