import React, { useState, useEffect, useCallback } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import { Container } from "@mui/material";
import RouteService from "@services/MapServices/RouteServices";
import Swal from "sweetalert2";
import Map from "@components/Maps/CustomComponents/Routes";
import { setVars, getVars } from "@utils/global";
import Filter from "@components/MapsModules/Routes/Filter";
import CatalogService from "@services/CatalogServices";
import PolygonsCard from "@components/MapsModules/Routes/PolygonsCard";
import geojson_ex_pol_data from "@data/polygons/poligonos.json";
import middleware from "@middlewares/middleware";
const elements = {
  seccion: [
    {
      title: "Seccion:",
      name: "seccion",
      type: "text",
    },
    {
      title: "Ruta:",
      name: "Ruta",
      type: "text",
    },
    {
      title: "Tipo:",
      name: "TipoSeccion",
      type: "text",
    },
    {
      title: "DLOC:",
      name: "DLOC",
      type: "text",
    },
    {
      title: "DFED:",
      name: "DFED",
      type: "text",
    },
    {
      title: "LNOM:",
      name: "Lnom",
      type: "text",
    },
    {
      title: "Casillas:",
      name: "TotalCasillas",
      type: "text",
    },
  ],
  dfed: [
    {
      title: "DFED:",
      name: "dfed",
      type: "text",
    },
  ],
};
const Maps = () => {
  const {user} = getVars("Token");
  //const navigate = useNavigate();
  const [polygons, setPolygons] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [polygonsOLD, setPolygonsOLD] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [filter, setFilter] = useState({
    idMunicipio: "",
    idDF: "",
    idDL: "",
  });
  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);
  const [selectedSections, setSelectedSections] = useState([]);
  const [valuesInfoGeneral, setValuesInfoGeneral] = useState(null);
  const [selectedSectionsProperties, setSelectedSectionsProperties] = useState([]);
  const [catalogs, setCatalogs] = useState({
    municipios: [],
  });
  /* const formik = useFormik({
    initialValues: FilteringInterface,
    validationSchema: FilteringSchema,
    onSubmit: (values) => {
      handleVerification(handleAttrs(values));
    },
  }); */
  /*   const handleVerification = (values) => {
    if (!isEmptyOrNullObject(values)) {
      Swal.fire({
        title: `¡Atención!`,
        text: "handleVerification",
        icon: "info",
        reverseButtons: true,
        showCancelButton: false,
        confirmButtonText: "Ok",
      });
      //handleSave(values);
    }
  }; */

  const getEmptyMap = () => {
    setPolygons({
      type: "FeatureCollection",
      features: [],
    });
  };

  const getSections = (params) => {
    RouteService.getSections({
      page: 0,
      pageSize: 10,
      value: params.value,
      shp: params.shp,
      idEntidad: user.idEstado
    })
      .then((res) => {
        if (res.results) {
          setPolygons(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  };
  const handleFilterExect = (_filter, polygon) => {
    
    let data_parameters = {};
    if(!polygon)
      data_parameters = {
        page: 0,
        pageSize: 10,
        filtered: _filter,
        idEntidad: user.idEstado
      };
    else
    data_parameters = {
      polygon,
      page: 0,
      pageSize: 10,
      filtered: _filter,
      idEntidad: user.idEstado
    };
    setFilter(_filter);
    setPolygons(null);
    RouteService.getSections(data_parameters)
      .then((res) => {
        if (res.results) {
          setPolygons(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
    if(_filter.length === 1){
      const data_pol_old =  geojson_ex_pol_data.features.filter(item => item.properties.idMunicipioReportes === _filter[0].value)
      setPolygonsOLD({
        type: "FeatureCollection",
        features: data_pol_old,
      });
      setVars("itemEdit", null);
      setVars("selectedSections", []);
      setVars("selectedSectionsProperties", []);
      setVars("selectedDFED", null);
      setVars("flagCreatePolygon", false);//agregue
      setVars("valueTab",0);
      setSelectedSections([]);
      setSelectedSectionsProperties([]);
      RouteService.getInformationMunicipality({page:0, pageSize:10, filtered:_filter})
      .then((res) => {
        if (res.results) {
          
          if(res.response.data.length!==0){
            setValuesInfoGeneral(res.response.data[0]);
          }
          else {
            Swal.fire({ title: res.message, icon: "warning" });
          }
          
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
    }
  };

  const loadCatalogs = useCallback(() => {
    let catalogsParams = [{ id: "municipios_dl", getAll:false }];
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          if (res.response.errors.length > 0) {
            Swal.fire({
              title:
                "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
              icon: "warning",
            });
          }

          const catalogsResponse = res.response.catalogs;
          setCatalogs((prevState) => {
            return {
              ...prevState,
              municipios: catalogsResponse.municipios_dl
                ? catalogsResponse.municipios_dl
                : [],
            };
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatalogs(false);
      });
  }, []);
  useEffect(() => {
    setVars("itemEdit", null);
    setVars("selectedSections", []);
    setVars("selectedSectionsProperties", []);
    setVars("selectedDFED", null);
    setVars("valueTab",1);
    setVars("filterRoutes", {
      idMunicipioReportes: "",
      idDF: "",
      idDL: ""
    })
    getEmptyMap();
    loadCatalogs();
    // eslint-disable-next-line
  }, []);
  const FilterComponent = (
    <Filter
      catalogs={catalogs}
      loadingCatalogs={isLoadingCatalogs}
      handleFilter={handleFilterExect}
    />
  );
  const handleRefreshShape = () => {
    setSelectedSections([]);
    handleFilterExect(filter, null);
  };
  const handleGetShapePolygonEvent = (item,flag) => {

    const _filter = [
      { id: "cat_secciones.idMunicipio", filter: "=", value: item.idMunicipio },
      { id: "cat_secciones.idDF", filter: "=", value: item.idDF },
      //{ id: "cat_secciones.idDL", filter: "=", value: item.idDL },
    ];
    setVars("filterRoutes",{
      idMunicipioReportes:item.idMunicipioReportes,
      idDF: item.idDF
    });
    if(flag)
    handleFilterExect(_filter,item.id);
    else handleFilterExect(_filter,null);
  };
  const handleViewDetail = (e) => {
    setPolygons(null);
    if (e.shp === "dfed") {
      getSections({ value: [e.dfed], shp: "dfed" });
    }
  };
  const handleChangeSections = (array,array_properties) => {
    setSelectedSections(array);
    setSelectedSectionsProperties(array_properties);
  };
  const handleSetSelectedSections = (e) => {
    setSelectedSections(e);
  }
  return (
    <AdminLayout>
      <Container maxWidth={false}>
        {/* <ActionHeader
        title="Mapas"
        handleclick={() => navigate("/map")}
      /> */}
        <Map
          data={polygons}
          polygonsOLD={polygonsOLD}
          CardComponentFlag={true}
          CardComponent={
            <PolygonsCard
              permissions={{
                Ver:1,
                Agregar:middleware.checkMenuActionId("Agregar"),
                Editar:middleware.checkMenuActionId("Editar"),
                Eliminar: middleware.checkMenuActionId("Eliminar")}
              }
              selectedSections={selectedSections}
              polygons={polygons}
              selectedSectionsProperties={selectedSectionsProperties}
              handleSetSelectedSections={handleSetSelectedSections}
              handleRefreshShape={handleRefreshShape}
              handleGetShapePolygonEvent={handleGetShapePolygonEvent}
              handleEmptyProperties={()=>{
                setVars("selectedSections", []);
                setVars("selectedSectionsProperties", []);
                setSelectedSections([]);
                setSelectedSectionsProperties([]);}}
            />
          }
          TitleCardComponent={"Rutas"}
          CardInfoFlag={true}
          CardInfoInitialValues={{
            title: "INFORMACIÓN",
            elements: elements[polygons ? polygons.shp : "dfed"],
          }}
          handleClickViewDetailEvent={handleViewDetail}
          FilterComponentFlag={true}
          FilterComponent={FilterComponent}
          handleChangeSections={handleChangeSections}
          valuesInfoGeneral={valuesInfoGeneral}
        ></Map>
      </Container>
    </AdminLayout>
  );
};
export default Maps;
