import {
    CVE_ESTADO,
    AMBITO_ESTATAL,
    AMBITO_REGIONAL,
    AMBITO_DL,
    AMBITO_DF,
    AMBITO_MPAL,
    AMBITO_MPIO_DL,
    AMBITO_POLIGONAL,
    AMBITO_SECCIONAL,
    AMBITO_MZA,
    AMBITO_CASILLA,
    AMBITO_MODULO
} from "@data/constants";
import { removeDefaultOption } from "@utils/Utilities";

export const changeResponsibility = ({ value, catalogs, profileInfo, values }) => {
    const label = profileInfo && profileInfo.label ? profileInfo.label.toUpperCase() : '';
    let scopeLabel = "";
    let catScope = [];

    let newValues = {
        ...values,
        idPerfil: value,
        idAmbitoCargo: profileInfo.idAmbitoCargo,
        AmbitoValorCargo: "",
    };

    return new Promise((resolve, reject) => {
        try {
            switch (profileInfo.idAmbitoCargo) {
                case AMBITO_ESTATAL:
                    scopeLabel = "";
                    newValues = {
                        ...newValues,
                        idAmbitoCargo: AMBITO_ESTATAL,
                        AmbitoValorCargo: CVE_ESTADO,
                        ResponsabilidadEtiqueta: label,
                    };
                    catScope = [];
                    break;
                case AMBITO_REGIONAL:
                    scopeLabel = "Región";
                    newValues = {
                        ...newValues,
                        idAmbitoCargo: AMBITO_REGIONAL,
                    };
                    catScope = removeDefaultOption(catalogs.regiones);
                    break;
                case AMBITO_DL:
                    scopeLabel = "Distrito Local";
                    newValues = {
                        ...newValues,
                        idAmbitoCargo: AMBITO_DL,
                    };
                    catScope = removeDefaultOption(catalogs.dl);
                    break;
                case AMBITO_DF:
                    scopeLabel = "Distrito Federal";
                    newValues = {
                        ...newValues,
                        idAmbitoCargo: AMBITO_DF,
                    };
                    catScope = removeDefaultOption(catalogs.df);
                    break;
                case AMBITO_MPAL:
                    scopeLabel = "Municipio";
                    newValues = {
                        ...newValues,
                        idAmbitoCargo: AMBITO_MPAL,
                    };
                    catScope = removeDefaultOption(catalogs.municipios);
                    break;
                case AMBITO_MPIO_DL:
                    scopeLabel = "Municipio";
                    newValues = {
                        ...newValues,
                        idAmbitoCargo: AMBITO_MPIO_DL,
                    };
                    catScope = removeDefaultOption(catalogs.municipios_dl);
                    break;
                case AMBITO_POLIGONAL:
                    scopeLabel = "Polígono";
                    newValues = {
                        ...newValues,
                        idAmbitoCargo: AMBITO_POLIGONAL,
                    };
                    catScope = removeDefaultOption(catalogs.poligonos);
                    break;
                case AMBITO_SECCIONAL:
                    scopeLabel = "Sección";
                    newValues = {
                        ...newValues,
                        idAmbitoCargo: AMBITO_SECCIONAL,
                    };
                    catScope = removeDefaultOption(catalogs.secciones);
                    break;
                case AMBITO_MZA:
                    scopeLabel = "Manzana";
                    newValues = {
                        ...newValues,
                        idAmbitoCargo: AMBITO_MZA,
                    };
                    break;
                case AMBITO_CASILLA:
                    scopeLabel = "Casilla";
                    newValues = {
                        ...newValues,
                        idAmbitoCargo: AMBITO_CASILLA,
                    };
                    break;
                case AMBITO_MODULO:
                    scopeLabel = "Módulo";
                    newValues = {
                        ...newValues,
                        idAmbitoCargo: AMBITO_MODULO,
                    };
                    catScope = removeDefaultOption(catalogs.modulos);
                    break;
                default:
                    break;
            }
            resolve({
                success: true,
                data: {
                    newValues,
                    catScope,
                    scopeLabel
                }
            });
        } catch (error) {
            reject({
                success: false,
                error
            });
        }
    });
};

export const changeScope = ({ value, catalogs, profileInfo, values, catScope }) => {
    return new Promise((resolve, reject) => {
        try {
            let newValues = {
                ...values,
                AmbitoValorCargo: parseInt(value),
                ResponsabilidadEtiqueta: "",
            };

            let scope = {};
            const label = profileInfo && profileInfo.label ? profileInfo.label.toUpperCase() : '';

            switch (newValues.idAmbitoCargo) {
                case AMBITO_REGIONAL:
                    newValues = {
                        ...newValues,
                        ResponsabilidadEtiqueta: `${label} - R${value}`,
                    };
                    break;
                case AMBITO_DF:
                    newValues = {
                        ...newValues,
                        ResponsabilidadEtiqueta: `${label} - DF${value}`,
                    };
                    break;
                case AMBITO_DL:
                    newValues = {
                        ...newValues,
                        ResponsabilidadEtiqueta: `${label} - DL${value}`,
                    };
                    break;
                case AMBITO_MPAL:
                    scope = catalogs.municipios.find((item) => item.value === value);
                    newValues = {
                        ...newValues,
                        ResponsabilidadEtiqueta: `${label} - ${scope.label}`,
                    };
                    break;
                case AMBITO_MPIO_DL:
                    scope = catalogs.municipios_dl.find((item) => item.value === value);
                    newValues = {
                        ...newValues,
                        idMunicipio: value,
                        ResponsabilidadEtiqueta: `${label} - ${scope.label}`,
                    };
                    break;
                case AMBITO_POLIGONAL:
                    scope = catalogs.poligonos.find((item) => item.value === value);
                    newValues = {
                        ...newValues,
                        ResponsabilidadEtiqueta: `${label} - ${scope.label}`,
                    };
                    break;
                case AMBITO_SECCIONAL:
                    scope = catalogs.secciones.find(
                        (item) => item.value === parseInt(value)
                    );
                    newValues = {
                        ...newValues,
                        ResponsabilidadEtiqueta: `${label} - ${scope.label}`,
                    };
                    break;
                case AMBITO_MZA:
                    scope = catScope.find((item) => item.value === value);
                    newValues = {
                        ...newValues,
                        ResponsabilidadEtiqueta: `${label} - ${scope.label}`,
                        Prioridad: scope.Prioridad,
                    };
                    break;
                case AMBITO_CASILLA:
                    scope = catScope.find((item) => item.value === value);
                    newValues = {
                        ...newValues,
                        ResponsabilidadEtiqueta: `${label} - ${scope.label}`,
                        Prioridad: scope.Prioridad,
                    };
                    break;
                case AMBITO_MODULO:
                    scope = catalogs.modulos.find((item) => item.value === value);
                    newValues = {
                        ...newValues,
                        ResponsabilidadEtiqueta: `${label} - ${scope.label}`,
                    };
                    break;
                default:
                    break;
            }

            resolve({ success: true, data: newValues });
        } catch (error) {
            reject({
                success: false,
                error
            });
        }
    })
}