import React, { useState, useEffect } from "react";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { Typography, Box, Divider, IconButton, CircularProgress, Grid, TextField, Button } from "@mui/material";
import { Timeline, TimelineItem, TimelineDot, TimelineConnector, TimelineContent, TimelineSeparator } from "@mui/lab";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Swal from "sweetalert2";
import moment from "moment/moment";
import "moment/locale/es";

import ImportExportIcon from "@mui/icons-material/ImportExport";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
// Servicios
import SupportServices from "@services/SupportServices";

function Historial({ userId, userName }) {
  const { height } = useWindowDimensions();
  // Establecer un estado para controlar si se está cargando el historial
  const [isLoading, setIsLoading] = useState(false);

  //Estados
  const orangeDotStyle = { backgroundColor: "orange" };
  // Nuevo estado para controlar el orden
  const [isReversed, setIsReversed] = useState(false);

  const [page] = useState(0);
  const [pageSize] = useState(20);
  const [, setTotal] = useState(0);

  const [userList, setUserList] = useState([]);
  const [, setHasMore] = useState(true);

  // Estados para almacenar las fechas seleccionadas
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //Funcion para traer el historial de los usuarios de la API
  const getUsers = async (data) => {
    try {
      // Iniciar la carga
      setIsLoading(true);

      const res = await SupportServices.getUserLogs(userId, {
        page: data.page,
        pageSize: data.pageSize,
      });

      if (res.results) {
        if (res.response.data.length > 0) {
          setUserList(res.response.data);
          setTotal(res.response.total);
        } else {
          setUserList([]);
          setTotal(0);
        }
        if (pageSize >= parseInt(res.response.total)) {
          setHasMore(false);
        }
      } else {
        setUserList([]);
        setHasMore(false);
      }
    } catch (e) {
      Swal.fire({ title: e.message, icon: "warning" });
      setHasMore(false);
    } finally {
      // Finalizar la carga
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Verificar si userId no es undefined antes de realizar cualquier acción
    if (userId) {
      // Cargar historial para el nuevo usuario
      getUsers({ page, pageSize });

      // Restablecer las fechas seleccionadas
      setStartDate(null);
      setEndDate(null);
    } else {
      setUserList([]);
    }
    // eslint-disable-next-line
  }, [userId]);

  //Funcion para el boton de invertir la UserList si esta acomodada en orden
  const reverseUserList = () => {
    setUserList([...userList].reverse());
    setIsReversed(!isReversed);
  };

  // Función para filtrar userList basado en las fechas seleccionadas
  const getFilteredUserListByDate = () => {
    if (!startDate && !endDate) {
      // Si no hay fechas seleccionadas, retorna la lista completa
      return userList;
    }

    return userList.filter((user) => {
      // Convertir la fecha del usuario a un objeto Date
      const userDate = new Date(user.Fecha);

      // Si solo se ha seleccionado la fecha de inicio
      if (startDate && !endDate) {
        return userDate >= startDate;
      }

      // Si solo se ha seleccionado la fecha final
      if (!startDate && endDate) {
        return userDate <= endDate;
      }

      // Si se han seleccionado ambas fechas
      if (startDate && endDate) {
        return userDate >= startDate && userDate <= endDate;
      }
      return true;
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        {/* Cabecera y filtrar historial por rango de fechas----- */}
        <Grid container alignItems="center" mb={2}>
          <Grid item xs={12} sm={5}>
            <Typography
              variant="h6"
              sx={{
                width: "100%",
                marginY: 1,
                marginLeft: 1,
                textAlign: "left",
                fontSize: "17px",
              }}
            >
              Cronología de la actividad del usuario
            </Typography>
          </Grid>

          {userId && userList.length > 0 && (
            <>
              <Grid item xs={12} sm={2.8}>
                <DatePicker
                  label="Fecha inicial"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  disabled={!userId || userList.length === 0} // Deshabilitar si userId no está definido y es vacio
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue); // Actualiza el estado cuando se selecciona una nueva fecha
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: true, // Esto asegura que el label siempre esté en la parte superior
                      }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "90%",
                        },
                        marginY: {
                          xs: 1, // Aplicar un margen vertical de 2 en pantallas extra pequeñas (teléfonos móviles)
                          sm: 0, // Aplicar un margen vertical de 0 en pantallas pequeñas y mayores
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.875rem",
                          padding: "10px 14px",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={2.8}>
                <DatePicker
                  label="Fecha final"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  disabled={!userId || userList.length === 0} // Deshabilitar si userId no está definido y es vacio
                  value={endDate} // Vincula el estado
                  onChange={(newValue) => {
                    setEndDate(newValue); // Actualiza el estado cuando se selecciona una nueva fecha
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: true, // Esto asegura que el label siempre esté en la parte superior
                      }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "90%",
                        },
                        marginY: {
                          xs: 1, // Aplicar un margen vertical de 2 en pantallas extra pequeñas (teléfonos móviles)
                          sm: 0, // Aplicar un margen vertical de 0 en pantallas pequeñas y mayores
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "0.875rem",
                          padding: "10px 14px",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={1.4}>
                <Button
                  fullWidth
                  variant="outlined"
                  disabled={!userId || userList.length === 0} // Deshabilitar si userId no está definido y es vacio
                  onClick={() => {
                    setStartDate(null);
                    setEndDate(null);
                  }}
                  sx={{
                    color: "rgb(0, 0, 126)",
                    borderColor: "rgb(0, 0, 126)",
                    marginY: {
                      xs: 1,
                      sm: 0,
                    },
                  }}
                >
                  Limpiar
                </Button>
              </Grid>
            </>
          )}
        </Grid>

        <Divider />

        {/* Icono y funcionalidad de filtrar el historial de los usuarios----- */}
        {userId && userList.length > 0 && (
          <IconButton onClick={reverseUserList} sx={{ position: "absolute", right: 70, mt: 1 }} disabled={userList.length === 0}>
            {isReversed ? (
              <ImportExportIcon
                sx={{
                  fontSize: "26px",
                }}
              />
            ) : (
              <CompareArrowsIcon
                sx={{
                  transform: "rotate(90deg)", // Rotar el icono 90 grados
                  fontSize: "26px",
                }}
              />
            )}
          </IconButton>
        )}

        {/* Mostrar nombre de usuario y si no ha seleccionado alguno------ */}
        <Typography variant="h6" sx={{ marginTop: 2, textAlign: "center", color: "rgb(0, 0, 129)" }}>
          {userId ? userName : "Selecciona un usuario"}
        </Typography>

        {/* Listado del historial de los usuarios--------- */}
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          // Mostrar el historial del usuario o mensaje si no hay historial
          <Box
            sx={{
              maxHeight: height * 0.5,
              overflowY: "scroll",
            }}
            marginTop={2}
          >
            {userList.length > 0 ? (
              // userList.map((user) => (
              getFilteredUserListByDate().map((user) => (
                <Timeline
                  key={user.id}
                  position="right"
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot style={orangeDotStyle} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ width: "100%", marginLeft: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: {
                            xs: "column", // En pantallas pequeñas, los elementos se apilan verticalmente
                            sm: "row", // En pantallas más grandes, se disponen en fila
                          },
                          justifyContent: "space-between",
                          width: "100%", // Asegurarse de que ocupa todo el ancho disponible
                        }}
                      >
                        <div>
                          <Typography
                            variant="h2"
                            sx={{
                              fontSize: {
                                xs: "13px", // Tamaño más pequeño en pantallas pequeñas
                                sm: "15px", // Tamaño normal en pantallas más grandes
                              },
                            }}
                          >
                            Acción: {user.Accion}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "12px",
                              marginLeft: {
                                xs: 0, // Sin margen izquierdo en pantallas pequeñas
                                sm: 20, // Margen normal en pantallas más grandes
                              },
                              marginBottom: {
                                xs: 2, // Sin margen izquierdo en pantallas pequeñas
                                sm: 0, // Margen normal en pantallas más grandes
                              },
                              color: "gray",
                            }}
                          >
                            {moment(user.Fecha, "YYYY-MM-DD HH:m").fromNow()}
                          </Typography>
                        </div>
                      </Box>

                      <Typography
                        variant="h8"
                        sx={{
                          marginTop: 2,
                          fontSize: "15px",
                          fontWeight: 400,
                        }}
                      >
                        {user.Comentario}
                      </Typography>
                      <Typography variant="h4" sx={{ marginTop: 2, fontSize: "13px" }}>
                        {user.Fecha}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              ))
            ) : (
              <Typography variant="h6" sx={{ textAlign: "center", fontSize: "13px" }}>
                {userId ? "Usuario sin actividad registrada" : ""}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
}

export default Historial;
