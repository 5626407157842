import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  styled,
  Button,
  Stack,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconInfo from "../../Images/info.png";
import { generateRandomString } from "@utils/global";
import { roundTo } from "../../Auxiliars/MathFunctions";
let style_b = {
  color: "#000000",
};
let style_square = {
  padding: "6px 8px",
  font: "14px/16px Arial, Helvetica, sans-serif",
  background: "rgba(255,255,255,0.8)",
  boxShadow: "0 0 15px rgba(0,0,0,0.2)",
  borderRadius: "5px",
  marginRight: "55px",
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const CardInfoViewDetail = ({
  initialValues,
  values,
  handleClickViewDetail,
}) => {
  const [showCard, setShowCard] = useState(true);
  /* 
    let exampleInitialValues = {
          title: "INFORMACIÓN",
          elements: [
            {
              title: "Seccion:",
              name: "seccion",
              color: "#000",
              type: "text",
            },
            {
              title: "Avance:",
              name: "avance",
              color: "#000",
              type: "int",
            },
            {
              title: "Porcentaje de Avance:",
              name: "avance_porcentual",
              color: "#000",
              type: "percent",
            },
          ],
        }; */
  const handleClick = () => {
    handleClickViewDetail(values);
  };
  return (
    <>
      {values !== null && values !== undefined && showCard ? (
        <>
          <div className="leaflet-control-container">
            <div className="leaflet-top leaflet-right">
              <div className="leaflet-control" style={style_square}>
                <Card className="card-secondary">
                  <CardHeader
                    title={initialValues.title}
                    style={{ textAlign: "center" }}
                    subheader={""}
                    titleTypographyProps={{ fontSize: "14px" }}
                    action={
                      <IconButton
                        onClick={() => {
                          setShowCard(false);
                        }}
                        aria-label="Minimizar"
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        {initialValues.elements.map((itemOfArray) => {
                          return (
                            <div key={"key_" + values[itemOfArray.name] + generateRandomString(5)}>
                              <Item style={{ fontSize: "12px", padding: "0px" }}>
                                {" "}
                                <b style={style_b}>
                                  <small>{itemOfArray.title}</small>
                                </b>
                                <b style={{ color: values.color === "#FFFFFF"?'#0066FF':values.color }}>
                                  <small>
                                    {itemOfArray.type === "text"
                                      ? (values[itemOfArray.name] || values[itemOfArray.name] === 0 ? values[itemOfArray.name] : "--")
                                      : itemOfArray.type === "int"
                                        ? new Intl.NumberFormat("en-EN").format(
                                          values[itemOfArray.name]
                                        )
                                        : itemOfArray.type === "percent"
                                          ? values[itemOfArray.name] === null
                                            ? "--"
                                            : roundTo(values[itemOfArray.name], 2) +
                                            "%"
                                          :
                                          itemOfArray.type === "color"
                                            ?
                                            <>
                                              <i style={{
                                                width: '18px',
                                                height: '18px',
                                                float: 'left',
                                                marginLeft: '35px',
                                                opacity: 0.7,
                                                background: values.color === "#FFFFFF"?'#0066FF':values.color
                                              }}></i>
                                              <b>{values[itemOfArray.name]}</b>
                                            </>
                                            :
                                            values[itemOfArray.name]}
                                  </small>
                                </b>
                              </Item>
                            </div>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </CardContent>
                  {values.shp !== "seccion" && initialValues.titleButton !== undefined ? (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Button
                          size="small"
                          style={{ borderRadius: "20px" }}
                          variant="outlined"
                          onClick={handleClick}
                        >
                          {initialValues.titleButton !== undefined ? initialValues.titleButton:'Ver Detalle'}
                        </Button>
                      </Stack>
                      <br />
                    </>
                  )
                      : (
                        <></>
                      )}
                </Card>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="leaflet-control-container">
          <div className="leaflet-top leaflet-right">
            <div
              className="leaflet-bar leaflet-control"
              style={{ marginRight: "55px" }}
            >
              <div
                onClick={() => setShowCard(true)}
                style={{ height: "30px", width: "30px" }}
              >
                <img
                  alt="Centrar vista"
                  style={{ backgroundColor: "white", padding: "5px" }}
                  src={IconInfo}
                  height={"30px"}
                  width={"30px"}
                ></img>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CardInfoViewDetail;
