import { Button, Icon, Stack, Typography, Link } from "@mui/material";
import React from "react";

const ActionHeader = ({
  title,
  titleLink,
  link,
  titleButton = "Agregar",
  iconButton = "add",
  isAction = false,
  isLink = false,
  isComponent = false,
  component,
  handleclick,
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" marginBottom={4}>
      <Typography variant="h5" fontWeight={700}>
        {title}
      </Typography>

      {isAction && (
        <Button
          variant="contained"
          size="small"
          color="primaryDark"
          startIcon={<Icon>{iconButton}</Icon>}
          onClick={(e) => handleclick()}
        >
          {titleButton}
        </Button>
      )}

      {isLink && (
        <Button
          component={Link}
          href={link}
          size="small"
          target="_blank"
          fontWeight={700}
          variant="outlined"
          /* endIcon={<OpenInNewIcon />} */
        >
          {titleLink}
        </Button>
      )}

      {isComponent && component}
    </Stack>
  );
};

export default ActionHeader;
