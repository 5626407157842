import React, { useState, useEffect } from "react";
import BasicTable from "@components/Tables/BasicTable";
import { Grid, Button, TextField, InputAdornment, IconButton, Icon,Tooltip } from "@mui/material";
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import RouteService from "@services/MapServices/RouteServices";
import Swal from "sweetalert2";
const Search = ({ handleGetShapePolygon }) => {
  const columns = [
    { id: "Ruta", label: "Ruta", numeric: true },
    { id: "Secciones", label: "Secciones", numeric: true },
    { id: "Lnom", label: "LNOM", numeric: true },
  ];
  const [polygons, setPolygons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [data, setData] = useState({
    page: 0,
    pageSize: 3,
    filtered: [],
    sorted: [],
  });

  useEffect(() => {
    getPolygons();
    // eslint-disable-next-line
  }, [data]);
  const getPolygons = () => {
    setLoading(true);
    RouteService.getRoutes(data)
      .then((res) => {
        if (res.results) {
          const polygonsData = res.response.data.map((item) => {
            return {
              Ruta: (
                <Button
                  variant="text"
                  color="primaryDark"
                  size="small"
                  style={{ fontSize: "9px" }}
                  onClick={() => handleEdit(item)}
                >
                  {item.Ruta}
                  <br />
                  ({item.Descripcion})
                  <br />
                  {item.Municipio} - {"DF:" + item.idDF}
                </Button>
              ),
              Secciones: (
                <div style={{ fontSize: "9px" }}>
                  <b>TS:</b>[{item.TotalSecciones}]
                  <b>TC:</b>[{item.TotalCasillas}]
                  <br />
                  {item.Secciones}
                </div>
              ),
              Lnom: (
                <div style={{ fontSize: "9px" }}>
                  <b>LNOM:</b> {item.Lnom}
                  <br />
                  <b>LNOMM:</b> {item.LnomM}
                  <br />
                  <b>LNOMH:</b> {item.LnomH}
                </div>
              ),
            };
          });

          setPolygons(polygonsData);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error, icon: "warning" }))
      .finally(() => setLoading(false));
  };
  const handleChangePagination = (pagination) => {
    setData({ ...data, ...pagination });
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleSearch = (e) => {
    if(e.key === "Enter" && e.target.value.length !== 0){
      const _isNaN = !isNaN(e.target.value);
      if (_isNaN)
        setData({
          ...data,
          filtered: [
            {
              id: "cat_secciones.Seccion",
              filter: "=",
              value: parseInt(e.target.value),
            },
          ],
        });
      else
        setData({
          ...data,
          filtered: [
            {
              id: "concat(rutas.Ruta,cat_municipios.Municipio, rutas.Descripcion)",
              filter: "LIKE",
              value: e.target.value,
            },
          ],
        });
    }
  };

  const handleClean = () => {
    setSearch("");
    setData({
      ...data,
      filtered: [],
    });
  };

  const handleEdit = (item) => {
    handleGetShapePolygon(item);
  };
  return (
    <>
      <Grid container rowSpacing={0}>
        {/* <Grid item sm={12}>
         
        </Grid> */}
        <Grid item sm={12}>
          <TextField
            sx={{ width: "100%" }}
            label="Buscar"
            type="text"
            name="Buscar"
            placeholder="Buscar por seccion, ruta, etc."
            onChange={handleChange}
            onKeyDown={handleSearch}
            value={search.toUpperCase()}
            variant="outlined"
            size="small"
            className="fixed-input"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Buscar">
                  <IconButton
                    color="secondary"
                    aria-label="upload picture"
                    component="label"
                    sx={{m:-1}}
                    onClick={() => handleSearch({key:"Enter",target:{value:search}})}
                  >
                    <Icon>search</Icon>
                  </IconButton>
                  </Tooltip>
                  <Tooltip title="Limpiar">
                    <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={() => handleClean()}
                  >
                    <CleaningServicesOutlinedIcon />
                  </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <BasicTable
            sx={{fontSize:"8px"}}
            rows={polygons}
            hcolumns={columns}
            pageProp={data.page}
            pageSize={data.pageSize}
            total={total}
            handleChangePagination={handleChangePagination}
            isLoading={loading}
            stickyHeader={true}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default Search;
