export const MENUS_PRIVILEGES = [
  "Ver",
  "Agregar",
  "Editar",
  "Eliminar",
  "Exportar",
  "Importar",
  "Autorizar",
  "Capturista",
  "Visible",
];

export const CVE_ESTADO = 11;
export const VIGENCIA_INE = 2024;
export const EJERCICIO = 2024;

//Ámbitos
export const AMBITO_ESTATAL = 1;
export const AMBITO_REGIONAL = 2;
export const AMBITO_DF = 3;
export const AMBITO_DL = 4;
export const AMBITO_MPAL = 5;
export const AMBITO_MPIO_DL = 6;
export const AMBITO_POLIGONAL = 7;
export const AMBITO_SECCIONAL = 8;
export const AMBITO_MZA = 9;
export const AMBITO_CASILLA = 10;
export const AMBITO_CAPTURA = 11;
export const AMBITO_MODULO = 14;

//Tipo de Responsabilidades
export const TIPO_GRAL = 1;
export const TIPO_ELECTORAL = 2;
export const TIPO_TERRITORIAL = 3;
export const TIPO_SOCIAL = 4;
export const TIPO_CANDIDATO = 5;

export const TypesResponsibilities = {
  "1": "Generales",
  "2": "Electoral",
  "3": "Territorial",
  "4": "Social",
  "5": "Candidato",
  "8": "Comité"
};

//Responsabilidades GENERALES
export const COORDINADOR_GENERAL = 99;
export const SIN_RESPONSABILIDAD = 1;

//Responsabilidades TERRITORIALES
export const TERRITORIAL_ESTATAL = 97;
export const TERRITORIAL_REGIONAL = 10;
export const TERRITORIAL_MPAL = 11;
export const TERRITORIAL_DISTRITAL = 19;
export const TERRITORIAL_POLIGONAL = 12;
export const TERRITORIAL_SECCIONAL = 13;
export const TERRITORIAL_MANZANERA = 14;

//Responsabilidades ELECTORALES
export const PERFIL_RC = 2;
export const PERFIL_RG = 3;
export const PERFIL_OBSERVER = 9;
export const PERFIL_FiL = 40; //Primero en Fila
export const PERFIL_ABOGADO = 8;
export const PERFIL_CASA = 15;
export const GUARDIAN_SECCION = 137;
export const LOGISTICA_ALIMENTOS = 141;


//Voluntarios ELECTORALES
export const VOL_RG = 2;
export const VOL_RC = 3;
export const VOL_OBSERVER = 11;
export const VOL_FiL = 12; //Primero en Fila
export const VOL_ABOGADO = 4;
export const VOL_CASA = 5; // CASA AZUL

//Estatus Usuario
export const USUARIO_INACTIVO = 1;
export const USUARIO_ACTIVO = 2;

//Pide Propietario
export const idPropietario = [
  PERFIL_RC
];
