/**
 * Interfaces
 */

const UserAddInterfaceV2 = {
  password: "",
  Confirm: "",
  idVoluntario: "",
  INE: "",
  VigenciaINE: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  FechaNacimiento: "",
  idSexo: "",
  idEstadoNacimiento: "",
  CalleVive: "",
  TipoVialidad: "C",
  NumExtVive: "",
  NumIntVive: "",
  idMunicipioVive: "",
  idLocalidadVive: "",
  ColoniaVive: "",
  CPVive: "",
  SeccionVota: "",
  Celular: "",
  Telefono: "",
  TelMensajes: "",
  Correo: "",
  Facebook: "",
  Instagram: "",
  Twitter: "",
  EsVoluntario: 0,
};

const UserAddInterface = {
  email: "",
  password: "",
  DefaultPage: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  idDependencia: 1,
  idCentroGto: "",
  idPerfil: "",
  Cargo: "",
  Celular: "",
  Telefono: "",
  Extension: "",
};

const UserUpdateInterface = {
  id: "",
  email: "",
  DefaultPage: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  idDependencia: 1,
  idCentroGto: "",
  idPerfil: "",
  Cargo: "",
  Celular: "",
};

const UserInfoInterface = {
  id: null,
  email: "",
  DefaultPage: "",
  PinCode: "",
  Nombre: "",
  Paterno: "",
  Materno: "",
  idDependencia: 0,
  Dependencia: "",
  NombreDependencia: "",
  idPerfil: 0,
  Perfil: "",
  idResponsabilidad: 0,
  idCargo: "",
  Cargo: "",
  Celular: "",
  Extension: "",
  idEstatus: 0,
  Estatus: "",
  TipoAcceso: "",
  UltimoAcceso: "",
  DeviceOS: "",
  DeviceID: "",
  FechaCreo: "",
  idUsuarioCreo: 0,
  RegistradoPor: "",
  FechaActualizo: "",
  idUsuarioActualizo: 0,
  idUsuarioPropietario: 0,
};

const UserProfileInterface = {
  id: null,
  Nombre: "",
  Paterno: "",
  Materno: "",
  Celular: "",
  Correo: "",
};

const UserPasswordInterface = {
  // id: 0,
  Password: "",
  Confirm: "",
};

const UserPinInterface = {
  // id: 0,
  PIN: "",
  Confirm: "",
};

const UserStatusInterface = {
  idEstatus: "",
  Motivo: "",
};

const UserAccessInterface = {
  idUsuario: null,
  TipoAcceso: "",
  FechaAcceso: "",
  HoraAcceso: "",
  IP: "",
  Navegador: "",
  Version: "",
  DeviceOS: "",
  Plataforma: "",
};

const UserRecoveryPasswd = {
  idEstado: 0,
  Celular: "",
  Contraseña: "",
  Confirmar: "",
};

export {
  UserAddInterfaceV2,
  UserInfoInterface,
  UserAddInterface,
  UserUpdateInterface,
  UserPasswordInterface,
  UserPinInterface,
  UserStatusInterface,
  UserAccessInterface,
  UserProfileInterface,
  UserRecoveryPasswd,
};
