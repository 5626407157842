import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import useWindowDimensions from "@hooks/useWindowDimensions";

// Material UI
import { CircularProgress, Card, CardContent } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import moment from "moment";

const TimeLine = (props) => {
  const { state } = props;
  const chartRef = useRef(null);
  const { height, width } = useWindowDimensions();

  const [data1, setData1] = useState(data(1));
  const [data2, setData2] = useState(data(2));
  const [data3, setData3] = useState(data(3));

  const [isLoading, setIsLoading] = useState(false);

  const [prevState, setPrevState] = useState(state); // State previo
  const [avance, setAvance] = useState(60000); // Avance de los datos (minutos, horas, dias, semanas, meses)

  // Intervalos para los datos del grafico y el navegador (scroll)
  let intervalData;
  let intervalNavigator;
  // Datos de avance del grafico y el navegador (scroll)
  let i = 0;
  let NI = 0;

  // Función para agregar puntos de datos aleatorios
  const addRandomData = () => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;
      const series0 = chart.series[0];
      const series1 = chart.series[1];
      const series2 = chart.series[2];

      const x = i * avance;
      const y0 = Math.round(Math.random() * 100);
      const y1 = Math.round(Math.random() * 100);
      const y2 = Math.round(Math.random() * 100);
      i++;

      if (series0.data) {
        // Establecer límite de datos en el chart
        const maxDataPoints = 100;
        const more = series0.data.length >= maxDataPoints;

        // Agregar los nuevos puntos de datos a las series
        series0.addPoint([x, y0], true, more);
        /* series1.addPoint([x, y1], true, more);
        series2.addPoint([x, y2], true, more); */

        chart.update({
          subtitle: {
            text: `Última actualización - ${moment().format('DD/MM/YYYY HH:mm')}`,
          },
        });
      }

      // Mantener el avance
      const eqls = i * avance - avance * 6;

      if (eqls / avance > 1) {
        // Evita que el Avance regrese a 0
        const xAxis = chart.xAxis[0];
        const NX = NI * avance;

        xAxis.setExtremes(NX, avance * 5 + NX);
      }

      // Función provicional para detener los datos random
      /* if (i === 10) {
        clearInterval(intervalData);
      } */
    }
  };

  // Función para reiniciar el navigator al llegar al final de los datos
  const resetNavigator = () => {
    const chart = chartRef.current.chart;
    const xAxis = chart.xAxis[0];

    const NX = NI * avance;
    const eqls = i * avance - avance * 6;

    if (eqls / avance > 0) {
      if (NX <= eqls) {
        xAxis.setExtremes(0 + NX, avance * 5 + NX);
      } else {
        // Si se llega al final, reiniciar desde el principio
        clearInterval(intervalNavigator);

        setTimeout(() => {
          NI = 0;
          xAxis.setExtremes(0, avance * 5);
          intervalNavigator = setInterval(resetNavigator, 3000);
        }, 5000);
      }
    }
    NI++;
  };

  // Actualizamos los datos al cambiar de estado
  useEffect(() => {
    if (prevState !== state) {
      setPrevState(state);
      // Reiniciamos nuestros contadores
      i = 0;
      NI = 0;
      // Detenemos nuestros intervalos
      clearInterval(intervalNavigator);
      clearInterval(intervalData);

      // Actualizamos los datos
      switch (state) {
        case 0:
          setData1(data(1));
          setData2(data(2));
          setData3(data(3));
          setAvance(60000);
          break;
        case 1:
          setData1(data(4));
          setData2(data(5));
          setData3(data(6));
          setAvance(3600000);
          break;
        case 2:
          setData1(data(7));
          setData2(data(8));
          setData3(data(9));
          setAvance(86400000);
          break;
        case 3:
          setData1(data(10));
          setData2(data(11));
          setData3(data(12));
          setAvance(604800000);
          break;
        case 4:
          setData1(data(13));
          setData2(data(14));
          setData3(data(15));
          setAvance(2629056000);
          break;
        default:
          break;
      }
    }
  }, [state]);

  // Cambia state por un nuevo estado y activamos los intervalos
  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;
      i = chart.series[0].yData.length;

      chart.update({
        rangeSelector: {
          selected: state,
        },
      });

      // Volvemos a activar los intervalos al cambiar el estado de los datos (ej. de minutos a horas)
      intervalData = setInterval(addRandomData, 6000);
      intervalNavigator = setInterval(resetNavigator, 3000);
    }

    return () => {
      clearInterval(intervalNavigator);
      clearInterval(intervalData);
    };
  }, [prevState]);

  // Opciones del grafico
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "line",
      spacingTop: width > 1400 ? 0 : 5,
      spacingBottom: 0,
      height: width > 430 ? height * 0.2 : 400,
      // height: height * 0.15
    },
    title: {
      text: `Avance de Captura Conforme al Tiempo - ${label(state)}`,
      style: {
        fontSize: width > 1400 ? width * 0.012 : "18px",
      },
    },
    subtitle: {
      text: `Última actualización - ${moment().format('DD/MM/YYYY HH:mm')}`,
      style: {
        fontSize: width > 1400 ? width * 0.0095 : "16px",
      },
    },
    xAxis: {
      type: "datetime",
      labels: {
        style: {
          fontSize: width > 1400 ? width * 0.01 : "14px",
        },
      },
    },
    // Titulos de la derecha
    yAxis: {
      title: {
        text: "Total",
        style: {
          fontSize: width > 1400 ? width * 0.01 : "16px",
        },
      },
      labels: {
        style: {
          fontSize: width > 1400 ? width * 0.01 : "14px",
        },
      },
    },
    // Barra inferior
    plotOptions: {
      series: {
        showInNavigator: true,
      },
    },
    navigator: {
      margin: 0,
      enabled: true,
      height: 30,
    },
    scrollbar: {
      enabled: false,
    },
    // Datos iniciales de la grafica
    series: [
      {
        name: "Ganada",
        data: data1,
        color: "#4FC24C",
      },
      /*  {
        name: "Switch",
        data: data2,
        color: "#C2A84C",
      },
      {
        name: "Perdible",
        data: data3,
        color: "#C24C4C",
      }, */
    ],
    // Botones para seleccionar el rango
    rangeSelector: {
      buttons: [],
      inputEnabled: false,
      selected: state,
    },
    exporting: {
      enabled: false,
    },
  };

  return (
    <Card className="card-primary" sx={{ height: "100%" }}>
      <CardContent>
        {isLoading ? (
          <Grid2
            container
            xs={12}
            height={width > 1440 ? height * 0.4 : 400}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={50} />
          </Grid2>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={options}
            ref={chartRef}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default TimeLine;

const data = (id) => {
  let data = [];
  switch (id) {
    case 1:
      return [
        [0, 0],
        [60000, 35],
      ];
    case 2:
      return [
        [0, 10],
        [60000, 90],
      ];
    case 3:
      return [
        [0, 20],
        [60000, 70],
      ];
    case 4:
      return [
        [0, 5],
        [3600000, 50],
      ];
    case 5:
      return [
        [0, 15],
        [3600000, 40],
      ];
    case 6:
      return [
        [0, 30],
        [3600000, 60],
      ];
    case 7:
      return [
        [0, 25],
        [86400000, 55],
      ];
    case 8:
      return [
        [0, 5],
        [86400000, 35],
      ];
    case 9:
      return [
        [0, 10],
        [86400000, 45],
      ];
    case 10:
      return [
        [0, 20],
        [604800000, 25],
      ];
    case 11:
      return [
        [0, 15],
        [604800000, 30],
      ];
    case 12:
      return [
        [0, 30],
        [604800000, 50],
      ];
    case 13:
      return [
        [0, 25],
        [2629056000, 45],
      ];
    case 14:
      return [
        [0, 5],
        [2629056000, 25],
      ];
    case 15:
      return [
        [0, 10],
        [2629056000, 35],
      ];

    default:
      return [];
  }

  return data;
};

const label = (id) => {
  switch (id) {
    case 0:
      return "Minuto";
    case 1:
      return "Hora";
    case 2:
      return "Dia";
    case 3:
      return "Semana";
    case 4:
      return "Mes";

    default:
      return "";
  }
};
