// Librerías
import React, { useState } from "react";
import { Container, Card, Grid, Box } from "@mui/material";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import Historial from "@components/Users/Historial";
import UserSearchHistorial from "@components/Users/UserSearchHistorial";

function UserActions() {
  const [userId, setuserId] = useState(null);
  const [userName, setuserName] = useState('');

  //Almacenar el id que viene desde usersearchhistorial
  const handleUserIdChange = (id) => {
    setuserId(id);
  };

  const handleUserNameChange = (name) => {
    setuserName(name);
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Historial de Acciones"
          isAction={false}
          titleButton="Regresar"
          iconButton="arrow_back"
        />

        <Box sx={{ mt: -2, mb: 1 }}>
          <Grid container>
            <Grid item xs={12} lg={4} sx={{ pr: { lg: 1 }, mt: 2 }}>
              <UserSearchHistorial
                userId={userId}
                handleUserIdChange={handleUserIdChange}
                handleUserNameChange={handleUserNameChange}
              />
            </Grid>

            <Grid item xs={12} lg={8} sx={{ pl: { lg: 1 }, mt: 2 }}>
              <Card
                className="card-primary"
                sx={{ height: "100%", padding: 2 }}
              >
                <Historial userId={userId} userName={userName} />
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </AdminLayout>
  );
}

export default UserActions;
