import React, { useEffect, useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserPassword from '@components/Users/UserPassword';
import UserPin from '@components/Users/UserPin';
import UserDevices from '@components/Users/UserDevices';
import LoadingForm from "@components/LinearProgress/LoadingForm";
import UserUpdateContext from './UserUpdateContext'

import UserService from "@services/UserServices";
import SoprteService from '@services/SupportServices'

const UserUpdate = (props) => {
    const {
        idUsuario,
        nombreCompleto,
        perfil,
        defaultOpen = false
    } = props;

    const fontExpand = { fontWeight: 'bold', mb: 2, fontSize: 18, textAlign: 'center' };
    const fontText = { display: 'flex', flexDirection: 'column', alignItems: 'center', fontWeight: 'bold', mb: 3, fontSize: 16 }
    const [API, setAPI] = useState({
        password: '',
        pin: '',
        dispositivo: '',
        deleteDevice: ''
    })

    const [isLoadingForm, setIsLoadingForm] = useState(true);
    const [isSuccessForm, setIsSuccessForm] = useState(false);
    const [openLinearProgress, setOpenLinearProgress] = useState(false);

    useEffect(() => {
        const Api = () => {
            if (perfil) {
                setAPI({
                    password: UserService.updatePassword,
                    pin: UserService.updatePIN,
                    dispositivo: UserService.getDevices,
                    deleteDevice: UserService.deleteDevice
                })
            } else {
                setAPI({
                    password: SoprteService.updatePassword,
                    pin: SoprteService.updatePIN,
                    dispositivo: SoprteService.getDevices,
                    deleteDevice: SoprteService.deleteDevice
                })
            }
        }
        Api()
    }, [perfil])
    return (
        <UserUpdateContext.Provider value={{ setIsLoadingForm, setOpenLinearProgress, setIsSuccessForm }}>
            <Box sx={{ marginBottom: 2 }}>
                <LoadingForm
                    isLoading={isLoadingForm}
                    success={isSuccessForm}
                    isOpen={openLinearProgress}
                    setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
                    loadinMessage={"Cargando..."}
                />
                <Accordion variant="outlined" defaultExpanded={defaultOpen}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1" sx={fontExpand}>
                            Contraseña y Seguridad
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Typography variant="subtitle1" sx={fontText}>
                            {nombreCompleto}
                        </Typography>
                        <UserPassword idUsuario={idUsuario} API={API.password} />
                    </AccordionDetails>
                </Accordion>
                <Accordion variant="outlined">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1" sx={fontExpand}>
                            Seguridad PIN
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Typography variant="subtitle1" sx={fontText}>
                            {nombreCompleto}
                        </Typography>
                        <UserPin idUsuario={idUsuario} API={API.pin} />
                    </AccordionDetails>
                </Accordion>
                <Accordion variant="outlined">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1" sx={fontExpand}>
                            Desvincular dispositivo
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Typography variant="subtitle1" sx={fontText}>
                            {nombreCompleto}
                        </Typography>
                        <UserDevices
                            idUsuario={idUsuario}
                            API={API.dispositivo}
                            APIDELETE={API.deleteDevice}
                        />
                    </AccordionDetails>
                </Accordion>
            </Box>
        </UserUpdateContext.Provider>
    );
};

export default UserUpdate;
