import React, { useState, useEffect } from "react";

//MUI
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SelectSearch from "../Selects/SelectSearch";

//Components
import InputSelect from "@components/Selects/BasicSelect";
import CatalogServices from "@services/CatalogServices";
import Swal from "sweetalert2";
import FilterCollapse from "@components/Filters/FilterCollapse";

const Filter = ({
  onChangeFilter,
  municipio = true,
  municipio_dl = false,
  poligono = false,
  seccion = true,
  manzana = true,
  checkRespon = false,
  checkResponsabilidades=false,
  responsable = false,
}) => {
  //variables
  const defaultOption = { value: 0, label: "TODOS" };
  //States
  const [, setCatRegions] = useState([]);
  const [catRegionsFilter, setCatRegionsFilter] = useState([]);
  const [catMunicipality, setCatMunicipality] = useState([]);
  const [catMunicipalityFilter, setCatMunicipalityFilter] = useState([]);
  const [catMunicipio, setCatMunicipio] = useState([]);
  const [catMunicipioFilter, setCatMunicipioFilter] = useState([]);
  const [catPolygon, setCatPolygon] = useState([]);
  const [catPolygonFilter, setCatPolygonFilter] = useState([]);
  const [catSection, setCatSection] = useState([]);
  const [catSectionFilter, setCatSectionFilter] = useState([]);
  const [clean, setClean] = useState(false);
  const [selectedPolygons, setSelectedPolygons] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [dataCatalog, setDataCatalog] = useState({
    Region: 0,
    Municipio: 0,
    Poligono: [],
    Seccion: [],
    Manzana: [],
    responsable: 0,
    Celular: "",
    NombreCompleto: "",
  });
  const [catResponsabilidades, setCatResponsabilidades] = useState([]);
  const [selectedManzana, setSelectedManzana] = useState([]);
  const [catManzanaFilter, setCatManzanaFilter] = useState([]);
  const [loadingBlocks, setLoadingBlocks] = useState(false);

  //Functions Api's
  useEffect(() => {
    const catalogsParams = [
      { id: "df", getAll: false },
      { id: "municipios", getAll: false },
      { id: "municipios_dl", getAll: false },
      { id: "poligonos", getAll: false },
      { id: "secciones", getAll: false },
      { id: "perfiles_hijos", filtered:[{ id: "perfiles.idTipo", filter: "=", value: 3}], getAll: false },
    ];

    CatalogServices.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          catalogsParams.forEach((item) => {
            switch (item.id) {
              case "df":
                setCatRegions(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                setCatRegionsFilter(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;

              case "municipios_dl":
                setCatMunicipality(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                setCatMunicipalityFilter(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;
              case "municipios":
                setCatMunicipio(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                setCatMunicipioFilter(
                  [defaultOption].concat(res.response.catalogs[item.id])
                );
                break;
              case "poligonos":
                setCatPolygon(res.response.catalogs[item.id]);
                setCatPolygonFilter(res.response.catalogs[item.id]);
                break;

              case "secciones":
                setCatSection(res.response.catalogs[item.id]);
                setCatSectionFilter(res.response.catalogs[item.id]);
               
              case "perfiles_hijos":
              setCatResponsabilidades( [defaultOption].concat(res.response.catalogs[item.id]));
              break;
              default:
            }
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((error) => Swal.fire({ title: error.message, icon: "warning" }));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //console.log("dataCatalog",dataCatalog);
    let newFilter = dataCatalog;

    if (!municipio_dl || !municipio) {
      newFilter = { ...newFilter, Municipio: 0 };
      setCatMunicipioFilter(catMunicipio);
      setCatMunicipalityFilter(catMunicipality);
    }
    if (!poligono) {
      setSelectedPolygons([]);
      newFilter = { ...newFilter, Poligono: [] };
      setCatPolygonFilter(catPolygon);
    }
    if (!seccion) {
      setSelectedSections([]);
      newFilter = { ...newFilter, Seccion: [] };
      setCatSectionFilter(catSection);
    }
    if (!manzana) {
      setSelectedManzana([]);
      newFilter = { ...newFilter, Manzana: [] };
    }

    setDataCatalog(newFilter);

    onChangeFilter(newFilter);
  }, [municipio, municipio_dl, poligono, seccion, manzana]);

  const handleChangeRegion = (event) => {
    const idRegion = event.target.value;
    setDataCatalog({
      ...dataCatalog,
      Region: idRegion,
      Municipio: 0,
      Poligono: [],
      Seccion: [],
    });

  

    if (idRegion > 0) {
      
      setCatMunicipalityFilter(
        [defaultOption].concat(
          catMunicipality.filter((item) => {
            if (typeof item.idDF === 'string') {
            let idDFArray = item.idDF.split(",");
            return idDFArray.includes(idRegion.toString());
            }
          })
        )
      );
       setCatMunicipioFilter(
        [defaultOption].concat(
          catMunicipality.filter((item) => {
            if (typeof item.idDF === 'string') {
            let idDFArray = item.idDF.split(",");
            return idDFArray.includes(idRegion.toString());
            }
          })
        )
      );

      setCatPolygonFilter(
        catPolygon.filter((item) => item.idDF === idRegion)
      );
      setCatSectionFilter(
        catSection.filter((item) => item.idDF === idRegion)
      );
    } else {
      setCatMunicipalityFilter(catMunicipality);
      setCatPolygonFilter(catPolygon);
      setCatSectionFilter(catSection);
    }
  };

  const handleChangeMunicipality = (e) => {
    const idMunicipality = e.target.value;

    setDataCatalog({
      ...dataCatalog,
      Municipio: idMunicipality,
      Poligono: [],
      Seccion: [],
    });

    if (idMunicipality > 0) {
      setCatPolygonFilter(
        catPolygon.filter((item) => item.idMunicipio === idMunicipality)
      );
      setCatSectionFilter(
        catSection.filter((item) => item.idMunicipio === idMunicipality)
      );
    } else {
      setCatSectionFilter(catSection);
      setCatPolygonFilter(catPolygon);
    }
  };

  const handleChangeMunicipio = (e) => {
    const idMunicipality = e.target.value;

    setDataCatalog({
      ...dataCatalog,
      Municipio: idMunicipality,
      Poligono: [],
      Seccion: [],
    });

    if (idMunicipality > 0) {
      setCatPolygonFilter(
        catPolygon.filter((item) => item.idMunicipioReportes === idMunicipality)
      );
      setCatSectionFilter(
        catSection.filter((item) => item.idMunicipioReportes === idMunicipality)
      );
    } else {
      setCatSectionFilter(catSection);
      setCatPolygonFilter(catPolygon);
    }
  };

  const handleChangePolygon = (newValue) => {
    setSelectedPolygons(newValue);
    const array_poligon = newValue.map((option) => option.value || option);

    setDataCatalog({
      ...dataCatalog,
      Poligono: array_poligon,
    });

    if (array_poligon.length > 0) {
      setCatSectionFilter(
        catSection.filter((item) =>
          array_poligon.some((id) => id === item.idPoligono)
        )
      );
    } else {
      setCatSectionFilter(catSection);
    }
  };

  const handleChangeSection = (newValue) => {
    setSelectedSections(newValue);
    const array_section = newValue.map((option) => option.value || option);
    setDataCatalog({
      ...dataCatalog,
      Seccion: array_section,
    });
    loadUrbanBlocks(array_section);
  };

  const handleChangeManzana = (newValue) => {
    setSelectedManzana(newValue);
    const array_manzanas = newValue.map((option) => option.value || option);
    setDataCatalog({
      ...dataCatalog,
      Manzana: array_manzanas,
    });
  };

  const handleChangeResponsable = (newValue) => {
    const res = newValue.target.value;
 
    setDataCatalog({
      ...dataCatalog,
      responsable: res,
    });
  };

  const hadleChangeTextField = (e) => {
 
    setDataCatalog({
      ...dataCatalog,
      [e.target.name]: e.target.value,
    });
  };

  const searchFilter = () => {
    const valueFilter = {
      Region: dataCatalog.Region,
      Municipio: dataCatalog.Municipio,
      responsable: dataCatalog.responsable,
      Responsabilidad: dataCatalog.responsable,
      Poligono: dataCatalog.Poligono,
      Seccion: dataCatalog.Seccion,
      Manzana: dataCatalog.Manzana,
      Celular: dataCatalog.Celular,
      NombreCompleto: dataCatalog.NombreCompleto,
    };

    onChangeFilter(valueFilter);
  };

  const clearFilter = () => {
    setClean(!clean);
    const newFilter = {
      Region: 0,
      Municipio: 0,
      responsable: 0,
      Poligono: [],
      Seccion: [],
      Manzana: [],
      Celular: "",
      NombreCompleto: "",
    };

    setCatMunicipalityFilter(catMunicipality);
    setCatPolygonFilter(catPolygon);
    setCatSectionFilter(catSection);

    setDataCatalog(newFilter);

    /* 
    setData({
      ...data,
      filtered: [],
    }); */
    onChangeFilter(newFilter);
  };

  const selectStyles = { 
    select: (provided, state) => ({
      ...provided,
      height: '150px', 
    }),
    menu: (provided, state) => ({
      ...provided,
      height: '150px', 
    }),
  };

  const loadUrbanBlocks = (section) => {
    setLoadingBlocks(true);
    const catalogsParams = [
      {
        id: "manzanas",
        filtered: [
          {
            id: "cat_manzanas.Seccion",
            filter: "IN",
            value: section,
          },
        ],
      },
    ];
    CatalogServices.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          setCatManzanaFilter(
            res.response.catalogs.manzanas ? res.response.catalogs.manzanas : []
          );
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: "warning",
        });
      })
      .finally(() => setLoadingBlocks(false));
  };

  return (
    <>
      <FilterCollapse>
        <Grid container spacing={2} marginBottom={"1rem"} marginTop={"0.5rem"} sx={{position:"relative"}}>
          <Grid item xs={12} md={poligono ? (seccion ? 3 : 6) : ( seccion ? 4 : 6)}>
            <InputSelect
              label="Distrito Federal"
              options={catRegionsFilter}
              name="region"
              value={
                catRegionsFilter.length === 2
                  ? catRegionsFilter[1].value
                  : dataCatalog.Region
              }
              onChange={handleChangeRegion}
              disabled={catRegionsFilter.length === 2 ? true : false}
              sx={{ width: "100%" }}
            />
          </Grid>
          {municipio && (
            <Grid item xs={12} md={poligono ? (seccion ? 3 : 6) : ( seccion ? 4 : 6)}>
              <InputSelect
                label="Municipio"
                options={catMunicipioFilter}
                name="municipio"
                value={
                  catMunicipioFilter.length === 2
                    ? catMunicipioFilter[1].value
                    : dataCatalog.Municipio
                }
                onChange={handleChangeMunicipality}
                disabled={catMunicipio.length === 2 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
          {municipio_dl && (
            <Grid item xs={12} md={poligono ? (seccion ? 3 : 6) : ( seccion ? 4 : 6)}>
              <InputSelect
                label="Municipio"
                options={catMunicipalityFilter}
                name="municipio"
                value={
                  catMunicipalityFilter.length === 2
                    ? catMunicipalityFilter[1].value
                    : dataCatalog.Municipio
                }
                onChange={handleChangeMunicipio}
                disabled={catMunicipality.length === 2 ? true : false}
                sx={{ width: "100%" }}
              />
            </Grid>
          )}
          {poligono && (
            <Grid item xs={12} md={poligono ? (seccion ? 3 : 6) : ( seccion ? 4 : 6)}>
               <SelectSearch
              multiple={true}
              placeholder="Polígono"
              closeMenuOnSelect={false}
              value={
                catPolygonFilter.length === 1
                  ? [catPolygonFilter[0]]
                  : selectedPolygons
              }
              onChange={handleChangePolygon}
              options={catPolygonFilter}
                isDisabled={catPolygonFilter.length === 1 ? true : false}
            />
            </Grid>
          )}
          {seccion && (
            <Grid item xs={12} md={3}>
                <SelectSearch
              multiple={true}
              placeholder="Sección"
              closeMenuOnSelect={false}
              value={
                catSectionFilter.length === 1
                ? [catSectionFilter[0]]
                : selectedSections
              }
              onChange={handleChangeSection}
              options={catSectionFilter}
              isDisabled={catSectionFilter.length === 1 ? true : false}
            />
            </Grid>
          )}
          {manzana && (
            <Grid item xs={12} md={4}>
              <SelectSearch
                placeholder="Manzana"
                styles={selectStyles}
                closeMenuOnSelect={false}
                value={
                  catManzanaFilter.length === 1
                    ? [catManzanaFilter[0]]
                    : selectedManzana
                }
                onChange={handleChangeManzana}
                multiple={true}
                isLoading={loadingBlocks}
                options={catManzanaFilter}
                isDisabled={catManzanaFilter.length === 1 ? true : false}
              />
              {dataCatalog.Seccion.length < 1 && (
                <Typography color={"blue"} variant="caption">
                  Seleccione una sección
                </Typography>
              )}
            </Grid>
          )}
          {
            /* manzana */ false && (
              <Grid item xs={12} md={4}>
                <InputSelect
                  label="Prioridad"
                  options={[]}
                  name="Prioridad"
                  value={
                    catMunicipalityFilter.length === 2
                      ? catMunicipalityFilter[1].value
                      : dataCatalog.Prioridad
                  }
                  onChange={handleChangeMunicipality}
                  disabled={catMunicipality.length === 2 ? true : false}
                  sx={{ width: "100%" }}
                />
              </Grid>
            )
          }
          {
            /* manzana */ false && (
              <Grid item xs={12} md={4}>
                <InputSelect
                  label="Rentabilidad"
                  options={[]}
                  name="Rentabilidad"
                  value={
                    catMunicipalityFilter.length === 2
                      ? catMunicipalityFilter[1].value
                      : dataCatalog.Rentabilidad
                  }
                  onChange={handleChangeMunicipality}
                  disabled={catMunicipality.length === 2 ? true : false}
                  sx={{ width: "100%" }}
                />
              </Grid>
            )
          }
        </Grid>
          <Grid
            container
            spacing={2}
            marginBottom={"1rem"}
            marginTop={"0.5rem"}
          >
            {checkResponsabilidades && (
              <Grid item xs={4} md={4}>
                <InputSelect
                  label="Responsable"
                  options={catResponsabilidades}
                  name="Responsable"
                  value={dataCatalog.responsable}
                  onChange={handleChangeResponsable}
                  disabled={false}
                  sx={{ width: "100%" }}
                />
              </Grid>
            )}
            {checkRespon && (
              <Grid item xs={4} md={4}>
                <InputSelect
                  label="Responsable"
                  options={[
                    { label: "Todos", value: 0 },
                    { label: "Sin Responsabilidad", value: 1 },
                    { label: "Con Responsabilidad", value: 2 },
                  ]}
                  name="Responsable"
                  value={dataCatalog.responsable}
                  onChange={handleChangeResponsable}
                  disabled={false}
                  sx={{ width: "100%" }}
                />
              </Grid>
            )}
            {responsable && (
              <>
            <Grid item xs={12} md={4}>
              <TextField
                label="Nombre"
                size="small"
                name="NombreCompleto"
                value={dataCatalog.NombreCompleto.toUpperCase()}
                onChange={hadleChangeTextField}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Celular"
                size="small"
                name="Celular"
                value={dataCatalog.Celular.toUpperCase()}
                onChange={hadleChangeTextField}
                sx={{ width: "100%" }}
              />
            </Grid>
            </>
            )}
          </Grid>
     

        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            color="primaryDark"
            onClick={searchFilter}
          >
            Filtrar
          </Button>
          <Button color="primaryDark" sx={{ ml: 1 }} onClick={clearFilter}>
            Limpiar
          </Button>
        </Box>
      </FilterCollapse>
    </>
  );
};

export default Filter;
