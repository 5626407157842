import React, { useState } from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconOptions from "../../Images/options.png";

let style_square = {
  padding: "6px 8px",
  font: "14px/16px Arial, Helvetica, sans-serif",
  background: "rgba(255,255,255,0.8)",
  boxShadow: "0 0 15px rgba(0,0,0,0.2)",
  borderRadius: "5px",
};
const CardInfo = ({ component, title }) => {
  const [showCard, setShowCard] = useState(true);

  return (
    <>
      {showCard ? (
        <>
          <div className="leaflet-control-container">
            <div className="leaflet-bottom leaflet-right">
              <div className="leaflet-control" style={style_square}>
                <Card className="card-secondary">
                  <CardHeader
                    title={title}
                    style={{ textAlign: "center", padding:'2px' }}
                    subheader={""}
                    titleTypographyProps={{ /* variant: "h6" */ fontSize:"16px" }}
                    action={
                      <IconButton
                        onClick={() => {
                          setShowCard(false);
                        }}
                        aria-label="Minimizar"
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    }
                  />
                  {/* <CardContent>{component}</CardContent> */}
                  {component}
                </Card>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="leaflet-control-container">
          <div className="leaflet-bottom leaflet-right">
            <div
              className="leaflet-bar leaflet-control"
              /* style={{ marginRight: "55px" }} */
            >
              <div
                onClick={() => setShowCard(true)}
                style={{ height: "30px", width: "30px" }}
              >
                <img
                  alt="Componente de opciones"
                  style={{ backgroundColor: "white", padding: "5px" }}
                  src={IconOptions}
                  height={"30px"}
                  width={"30px"}
                ></img>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CardInfo;
