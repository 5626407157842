import React, { useState /*useEffect*/ } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Container,
  Icon,
  Tab,
  Box,
  CardMedia,
} from "@mui/material";
import { TabList, TabContext, TabPanel } from "@mui/lab";

import Logo from "@assets/img/perfil_pan.jpeg";
import { getVars, setVars } from "@utils/global";
import useWindowDimensions from "@hooks/useWindowDimensions";
import AdminLayout from "@components/MainPage/AdminLayout";
import UserSecurity from "@components/Users/UserSecurity";
import UserForm from "@components/Users/UserFormBack";
import UserAccess from "@components/Users/UserAccess";
import MenusListAll from "@components/Menus/MenusListAll";
import { UserProfileInterface } from "@data/interfaces/UserInterfaces";

const UserProfile = (props) => {
  const { height } = useWindowDimensions();
  const [tabValue, setTabValue] = useState("1");
  const [webMenus,] = useState([]);
  const [appMenus,] = useState([]);
  const [userInfo, setUserInfo] = useState(() => {
    let token = getVars("Token").user;
    let user = UserProfileInterface;
    Object.keys(token).forEach((key) => {
      user[key] =
        token[key] !== undefined ? token[key] : UserProfileInterface[key];
    });
    return user;
  });

  const handleChangeTab = (e, newVal) => {
    setTabValue(newVal);
  };

  const handleChangeUserInfo = (newValue) => {
    let token = getVars("Token");
    setVars("Token", {
      ...token,
      user: {
        ...token.user,
        Nombre: newValue.Nombre,
        Paterno: newValue.Paterno,
        Materno: newValue.Materno,
        Celular: newValue.Celular,
        Correo: newValue.Correo,
      }
    });

    setUserInfo(() => {
      let user = userInfo;
      Object.keys(newValue).forEach((key) => {
        if (user[key] !== undefined) user[key] = newValue[key];
      });
      return user;
    });
  };
  return (
    <AdminLayout>
      <Container id="container" maxWidth={false}>
        <Card className="card-primary">
          <CardHeader
            title="Mi perfil"
            subheader="Datos de usuario"
            avatar={<Icon>person</Icon>}
          />
          <CardContent className="p-0">
            <Grid container spacing={2}>
              <Grid xs={12} md={4} sm={12} lg={5} item>
                <Card className="card-secondary">
                  <CardMedia
                    component="img"
                    height="250"
                    image={Logo}
                    alt="green iguana"
                    sx={{ objectFit: "fill" }}
                  />
                  <CardContent>
                    <UserSecurity
                      height={height * 0.6}
                      data={userInfo}
                      access="profile"
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={12} md={8} sm={12} lg={7} item>
                <TabContext value={tabValue}>
                  <Box>
                    <TabList
                      onChange={handleChangeTab}
                      aria-label="tab for user module"
                      textColor="primary"
                      indicatorColor="primary"
                      start
                      scrollButtons="auto"
                    >
                      <Tab
                        icon={<Icon>info</Icon>}
                        iconPosition="start"
                        label="Información"
                        value="1"
                      />
                      {/* <Tab
                        icon={<Icon>admin_panel_settings</Icon>}
                        iconPosition="start"
                        label="Menús"
                        value="2"
                      />
                      <Tab
                        icon={<Icon>exit_to_app</Icon>}
                        iconPosition="start"
                        label="Accesos"
                        value="3"
                      /> */}
                    </TabList>
                  </Box>

                  <TabPanel value="1">
                    <Card className="card-secondary">
                      <UserForm
                        action="edit"
                        data={userInfo}
                        handleChangeUserInfo={handleChangeUserInfo}
                        isAdmin={false}
                      />
                    </Card>
                  </TabPanel>
                  <TabPanel value="2">
                    <Card className="card-secondary">
                      <MenusListAll
                        webMenus={webMenus}
                        appMenus={appMenus}
                        type="list"
                      />
                    </Card>
                  </TabPanel>
                  <TabPanel value="3">
                    <UserAccess data={userInfo} />
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default UserProfile;
