import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Icon,
  InputAdornment,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { TreeItem, TreeView } from "@mui/lab";

const ListCasillas = ({ title, handleSave, data = [] }) => {
  const [checkedCasillas, setCheckedCasillas] = useState([]);
  const [hour, setHour] = useState("");
  const [validHour, setValidHour] = useState(true);

  const handleToggleAllChecked = (isChecked) => {
    let updatedCasillas = [];

    if (isChecked) {
      data.forEach((item) => {
        updatedCasillas.push({
          idCasilla: item.id,
          idTipoPropietario: item.idTipoPropietario ?? null,
          idUsuario: item.idUsuario ?? null,
        });

        item.Propietarios.forEach((propietario) => {
          updatedCasillas.push({
            idCasilla: item.id,
            idTipoPropietario: propietario.idTipoPropietario,
            idUsuario: propietario.idUsuario,
          });
        });
      });
    }

    setCheckedCasillas(updatedCasillas);
  };

  const handleToggle = (isChecked, item, propietario = null) => {
    let updatedCasillas;

    if (propietario) {
      updatedCasillas = isChecked
        ? [
            ...checkedCasillas,
            {
              idCasilla: item.id,
              idTipoPropietario: propietario.idTipoPropietario,
              idUsuario: propietario.idUsuario,
            },
          ]
        : checkedCasillas.filter(
            (casilla) => casilla.idUsuario !== propietario.idUsuario
          );
    } else {
      // Para el elemento principal
      updatedCasillas = isChecked
        ? [
            ...checkedCasillas,
            {
              idCasilla: item.id,
              idTipoPropietario: item.idTipoPropietario ?? null,
              idUsuario: item.idUsuario ?? null,
            },
            ...item.Propietarios.map((propietario) => ({
              idCasilla: item.id,
              idTipoPropietario: propietario.idTipoPropietario,
              idUsuario: propietario.idUsuario,
            })),
          ]
        : checkedCasillas.filter((casilla) => casilla.idCasilla !== item.id);
    }

    setCheckedCasillas(updatedCasillas);
  };

  const isChecked = (item, propietario = null) => {
    if (propietario) {
      return checkedCasillas.some(
        (casilla) =>
          casilla.idCasilla === item.id &&
          casilla.idTipoPropietario === propietario.idTipoPropietario &&
          casilla.idUsuario === propietario.idUsuario
      );
    }

    return (
      checkedCasillas.some((casilla) => casilla.idCasilla === item.id) &&
      (!item.Propietarios ||
        item.Propietarios.every((propietario) =>
          checkedCasillas.some(
            (casilla) =>
              casilla.idCasilla === item.id &&
              casilla.idTipoPropietario === propietario.idTipoPropietario &&
              casilla.idUsuario === propietario.idUsuario
          )
        ))
    );
  };

  const isCheckedAll = () => {
    return (
      data.length > 0 &&
      checkedCasillas.length ===
        data.length +
          data.reduce((acc, item) => acc + item.Propietarios.length, 0)
    );
  };

  const indeterminate = () => {
    const totalChecked = checkedCasillas.length;
    return (
      totalChecked > 0 &&
      totalChecked <
        data.length +
          data.reduce((acc, item) => acc + item.Propietarios.length, 0)
    );
  };

  const handleChangeValueHour = (inputHour) => {
    const rgx = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    setValidHour(rgx.test(inputHour));
    setHour(inputHour);
  };

  return (
    <Card className="card-primary">
      <CardContent>
        <Typography
          textAlign={"center"}
          variant="body2"
          fontWeight={600}
          marginBottom={2}
        >
          {title}
        </Typography>
        <Box marginBottom={2}>
          <Typography variant="body2" fontWeight={600}>
            Selecciona la casilla
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheckedAll()}
                onChange={(e) => handleToggleAllChecked(e.target.checked)}
                indeterminate={indeterminate()}
              />
            }
            label={
              <Typography variant="subtitle2" fontWeight={600}>
                TODOS
              </Typography>
            }
          />
          <Divider />

          <TreeView
            sx={{ width: "100%" }}
            defaultCollapseIcon={<Icon>expand_more</Icon>}
            defaultExpandIcon={<Icon>chevron_right</Icon>}
          >
            {data.map((item) => (
              <TreeItem
                key={item.id}
                nodeId={item.id.toString()}
                label={
                  <ListItemButton>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={isChecked(item)}
                        onChange={(e) => handleToggle(e.target.checked, item)}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`SECCIÓN ${item.Seccion} - ${item.NombreCasilla}`}
                    />
                  </ListItemButton>
                }
              >
                {item.Propietarios.map((propietario) => (
                  <TreeItem
                    key={propietario.idUsuario}
                    nodeId={propietario.idUsuario.toString()}
                    label={
                      <ListItemButton>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={isChecked(item, propietario)}
                            onChange={(e) =>
                              handleToggle(e.target.checked, item, propietario)
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={propietario.RC} />
                      </ListItemButton>
                    }
                  />
                ))}
              </TreeItem>
            ))}
          </TreeView>
        </Box>

        <Box marginBottom={2}>
          <Typography variant="body2" fontWeight={600}>
            Hora de pase de lista
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <TextField
            label={"* Hora de Pase de Lista (Formato: 24 hrs, ejemplo: 13:30)"}
            name="hora"
            value={hour}
            onChange={(e) => handleChangeValueHour(e.target.value)}
            size="small"
            sx={{ width: "100%", mb: 3 }}
            InputProps={{
              size: "small",
              endAdornment: (
                <InputAdornment position="end">
                  <Icon>schedule</Icon>
                </InputAdornment>
              ),
            }}
            error={!validHour}
            helperText={!validHour && "Ingrese una hora válida"}
          />
        </Box>
        <Stack direction={"row"} justifyContent={"center"}>
          <Button
            variant="contained"
            color="primaryDark"
            size="small"
            disabled={
              !checkedCasillas.length > 0 || hour.length === 0 || !validHour
            }
            onClick={() =>
              handleSave({ casillas: checkedCasillas, FechaHora: hour })
            }
          >
            Guardar
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ListCasillas;
