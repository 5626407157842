import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, GeoJSON, ZoomControl } from "react-leaflet";
import L from "leaflet";
import { style_original } from "../Auxiliars/ColorFeature";
import CardInfo from "../InternalComponents/Cards/CardInfo";
import CardGeneralInfo from "../InternalComponents/Cards/CardGeneralInfo";
import CardComponentContainer from "../InternalComponents/Cards/CardComponent";
import ControlFilter from "../InternalComponents/Cards/ControlFilter";
import { MagicSpinner } from "react-spinners-kit";
import { style_spinner } from "../Styles/Spinners";
import ControlCenterView from "../InternalComponents/Cards/ControlCenterView";
import MapViewType from "../InternalComponents/Cards/MapViewType";
import "leaflet.fullscreen/Control.FullScreen.js";
import "leaflet.fullscreen/Control.FullScreen.css";
import "../Styles/styleTooltip.css"
let bbox = require("geojson-bbox");
const Map = ({
  zoom,
  position,
  data,
  sub_data,
  CardInfoFlag,
  CardInfoInitialValues,
  CardComponentFlag,
  CardComponent,
  TitleCardComponent,
  handleClickViewDetailEvent,
  FilterComponentFlag,
  FilterComponent,
  flagInfoGeneral,
  valuesInfoGeneral,
  initialValuesInfoGeneral,
}) => {
  const geoJsonRef = useRef();
  const geoJsonBlocksRef = useRef();
  const _position = position ? position : [20.816157, -101.021312];
  const _flagInfoGeneral = flagInfoGeneral ? flagInfoGeneral : false;
  const _cardInfo = CardInfoFlag ? CardInfoFlag : false;
  const _cardInfoInitialValues = CardInfoInitialValues
    ? CardInfoInitialValues
    : {
        title: "INFORMACIÓN",
        elements: [
          {
            title: "Seccion:",
            name: "seccion",
            color: "#000",
            type: "text",
          },
        ],
      };
  const subInfo = {
    title: "INFORMACIÓN",
    elements: [
      {
        title: "Manzana:",
        name: "manzana",
        color: "#000",
        type: "text",
      },
      {
        title: "Seccion:",
        name: "Seccion",
        color: "#000",
        type: "text",
      },
      {
        title: "Localidad:",
        name: "Localidad",
        color: "#000",
        type: "text",
      },
      {
        title: "Prioridad:",
        name: "Prioridad",
        color: "#000",
        type: "text",
      },
      
    ],
  };
  const _cardComponentFlag = CardComponentFlag ? CardComponentFlag : false;
  const _cardComponent = CardComponent ? CardComponent : <></>;
  const _filterComponentFlag = FilterComponentFlag
    ? FilterComponentFlag
    : false;
  const _filterComponent = FilterComponent ? FilterComponent : <></>;
  const [_zoom] = useState(zoom ? zoom : 9);
  const [flagShowOldShape, setFlagShowOldShape] = useState(false);

  const [_data, setData] = useState(null);
  const [_sub_data, setSubData] = useState(null);
  const [feature, setFeature] = useState({ properties: null });
  const [subFeature, setSubFeature] = useState({ properties: null });
  
  const mapRef = useRef();
  const [viewMap, setViewMap] = useState({
    //attribution: "",
    //url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
    url: "https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}&s=Ga",
    attribution: "",
    maxZoom: 20,
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
  });
  var selectedFeature = null;
  var selectedSubFeature = null;
  useEffect(() => {
    setFlagShowOldShape(false);
    setFeature({ properties: null });
    setSubFeature({ properties: null });
    setData(data);
    setSubData(sub_data);
    
    if (geoJsonRef.current) {
      geoJsonRef.current.clearLayers(); // remove old data
      geoJsonRef.current.addData(data); // might need to be geojson.features
    }
    if (geoJsonBlocksRef.current) {
      geoJsonBlocksRef.current.clearLayers(); // remove old data
      geoJsonBlocksRef.current.addData(sub_data); // might need to be geojson.features
    }
    
  }, [data, sub_data]);
  const onEachFeature = (feature, layer) => {
    //Organizamos los eventos del GeoJson.
    if (feature.properties[feature.properties.shp] !== undefined) {
      /* if (feature.properties.shp === 'manzana') {
        layer.bindTooltip("M:"+feature.properties.manzana + " P"+feature.properties.Prioridad, { permanent: true, direction: "center" });
      } */
      if (feature.properties.shp === 'seccion' && data.features.length === 1 && sub_data) {
        //layer.bindTooltip("M:"+feature.properties.manzana + " L"+feature.properties.Localidad, { permanent: true, direction: "center" });
        return true;
      }
      
      layer.setStyle(style_original);
      layer.on({
        //mouseout: resetHighlight,
        //mouseover: mouseOverFeature,
        click: clickFeature,
      });
    }
  };
  var geojson =
    _data !== null ? (
      <>
        <GeoJSON
          ref={geoJsonRef}
          onEachFeature={onEachFeature}
          style={style_original}
          key={"geojson"}
          data={_data}
        ></GeoJSON>
      </>
    ) : null;

  var geojson_blocks =
  _sub_data !== null ? (
    <>
      <GeoJSON
        ref={geoJsonBlocksRef}
        onEachFeature={onEachFeature}
        style={style_original}
        key={"geojson_blocks"}
        data={_sub_data}
      ></GeoJSON>
    </>
  ) : null; 

  const highlightFeature = (e) => {
    var layer = e.target;
    if (geoJsonBlocksRef.current) {
      if(selectedFeature){
        resetHighlight(selectedFeature);
      }
      if (selectedSubFeature) {
        resetHighlight(selectedSubFeature);
      }
      selectedSubFeature = e;
      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
      }
      layer.setStyle(style_original(layer.feature, true));
      setSubFeature(layer.feature);
      return true;
    }
    if (selectedFeature) {
      resetHighlight(selectedFeature);
    }
    if (!layer.feature.properties.seccion) {
      resetHighlight(e);
    }
    setFeature(layer.feature);
    selectedFeature = e;
    if (e.target.feature.properties.seccion) {
      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
      }
      layer.setStyle(style_original(layer.feature, true));
    }
  };
  const clickFeature = (e) => {
    highlightFeature(e);
    //Aqui configuramos lo que queremos que haga un feature cuando le den click.
  };
  const resetHighlight = (e) => {
    //Con esto reseteamos el color en donde paso el mouse, para que no siga marcado.
    var layer = e.target;

    layer.setStyle(style_original(e.target.feature));
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  };
  /* const mouseOverFeature = (e) =>{
  }  */
  const centerFeature = (map, extent) => {
    map.flyToBounds([
      [extent[1], extent[0]],
      [extent[3], extent[2]],
    ]);
  };
  const whenCreatedExecute = (mapInstance) => {
    let map = mapInstance.target;
    if (!data || data.features.length === 0) return;
    let extent = bbox(data);
    centerFeature(map, extent);
  };
  const handleCenter = () => {
    let extent = bbox(data);
    centerFeature(mapRef.current, extent);
  };

  const handleViewMapType = () => {
    if (viewMap.url === "https://tile.openstreetmap.org/{z}/{x}/{y}.png") {
      setViewMap({
        attribution: "",
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      });
    } else if (
      viewMap.url ===
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
    ) {
      setViewMap({
        url: "https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}&s=Ga",
        attribution: "",
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      });
    } else {
      setViewMap({
        attribution: "",
        url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
      });
    }
  };
  return (
    <>
      {data !== null ? (
        <MapContainer
          ref={mapRef}
          center={_position}
          zoom={_zoom}
          zoomControl={false}
          style={{ width: "100%", height: "85vh" }}
          whenReady={whenCreatedExecute}
          fullscreenControl={true}
          fullscreenControlOptions={{
            position: "topright", // cambiar de posicion en el mapa
            title: "¡Mirar en pantalla completa!", // texto al acercar el mouse
            titleCancel: "¡Salir de pantalla completa!", // texto al acercar el mouse en pantalla completa
            content: null, // contenido del button, puede ser html, por defecto es null
            forceSeparateButton: true, // separar del boton de zoom
            forcePseudoFullscreen: false, // pantalla completa del ordenador o del navegador (si está en false toma pantalla del ordenador)
            fullscreenElement: false, // Elemento Dom para renderizar en pantalla completa,  (si está en false recurre el map container)
          }}
        >
          {/* <TileLayer attribution={viewMap.Attribution} url={viewMap.Url}/> */}
          <TileLayer {...viewMap} />
          <ZoomControl position="topright" />
          {geojson_blocks}
          {geojson}
          
          {_cardInfo ? (
            <CardInfo
              values={!feature.properties?subFeature.properties?subFeature.properties:null:feature.properties}
              initialValues={subFeature.properties ? subInfo:_cardInfoInitialValues}
              handleClickViewDetail={handleClickViewDetailEvent}
            />
          ) : (
            <></>
          )}
          {_flagInfoGeneral  ? (
            <CardGeneralInfo
              handleShowOldShape={() => {
                setFlagShowOldShape(!flagShowOldShape);
              }}
              values={valuesInfoGeneral}
              initialValues={initialValuesInfoGeneral}
            />
          ) : (
            <></>
          )}

          {_cardComponentFlag ? (
            <CardComponentContainer
              component={_cardComponent}
              title={TitleCardComponent}
            ></CardComponentContainer>
          ) : (
            <></>
          )}
          {_filterComponentFlag ? (
            <ControlFilter
              component={_filterComponent}
              title={"Filtro"}
            ></ControlFilter>
          ) : (
            <></>
          )}

          <ControlCenterView handleClickEvent={handleCenter} />
          <MapViewType handleClickEvent={handleViewMapType} />
        </MapContainer>
      ) : (
        <div style={style_spinner}>
          <MagicSpinner
            size={70}
            color={"#008000"}
            loading={true}
          ></MagicSpinner>
        </div>
      )}
    </>
  );
};
export default Map;
