import React, { useState, useCallback } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Icon,
  Card,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
  FormHelperText,
  Divider,
  Link,
} from "@mui/material";
import BasicSelect from "@components/Selects/BasicSelect";
import { ThemeProvider } from "@mui/material/styles";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { theme } from "@src/theme/index";
import Yup from "@utils/Yupi18n";

import { setVars, RECAPTCHA_SITE_KEY, Version } from "@utils/global";
import ApiExec from "@utils/ApiExec";
import { isEmptyOrInvalidString } from "@utils/validations";
import cat_entidad from "@data/json/cat_entidad.json";
import avatar from "@assets/img/c27.png";
import "@pages/auth/LoginStyle.css";
// import ReCAPTCHA from "react-google-recaptcha";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";


const CardHeader = () => {
  return (
    <>
      <div className="card-header-multimedia">
        <img alt="Logo" src={avatar} width={120} height={120} />
        <Typography
          sx={{ textAlign: "center", mt: 2 }}
          variant="h5"
          fontWeight={400}
        >
          Iniciar Sesión
        </Typography>
      </div>
    </>
  );
};

const CardBody = ({
  showPassword,
  setShowPassword,
  formik,
  handleOnKeyPress,
  // captchaRef,
  // onChangeCaptcha,
}) => {
  let navigate = useNavigate();
  return (
    <>
      <div className="card-content">
        {/* <Grid item sm={12}> */}
        <BasicSelect
          name="idEstado"
          label="Estado"
          options={cat_entidad.data}
          value={formik.values.idEstado}
          onChange={formik.handleChange}
          isLoading={false}
          sx={{ width: "100%", mb: 1 }}
        />
        {formik.errors.idEstado && formik.touched.idEstado && (
          <FormHelperText error>Seleccione un estado</FormHelperText>
        )}
        {/* 
        </Grid> */}
        <br />
        <TextField
          //label="Usuario"
          placeholder="Usuario"
          className="input"
          size="small"
          sx={{ mb: 1 }}
          error={
            formik.touched.username &&
            !isEmptyOrInvalidString(formik.errors.username)
          }
          helperText={
            formik.touched.username &&
            formik.errors.username &&
            formik.errors.username
          }
          type="text"
          name="username"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.username}
          autoFocus
          onKeyPress={handleOnKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton sx={{ cursor: "inherit" }}>
                  <Icon>account_circle</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          //label="Contraseña"
          placeholder="Contraseña"
          className="input"
          size="small"
          error={
            formik.touched.password &&
            !isEmptyOrInvalidString(formik.errors.password)
          }
          helperText={
            formik.touched.password &&
            formik.errors.password &&
            formik.errors.password
          }
          type={showPassword ? "text" : "password"}
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          onKeyPress={handleOnKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? (
                    <Icon>visibility_off</Icon>
                  ) : (
                    <Icon>visibility</Icon>
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* <Box
          marginTop={3}
          marginBottom={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <ReCAPTCHA
            sitekey={RECAPTCHA_SITE_KEY}
            ref={captchaRef}
            onChange={onChangeCaptcha}
          />
        </Box> */}
        <div style={{ textAlign: "end", marginTop:2 }}>
          <Typography
            component={Link}
            onClick={()=>navigate( `/cambio-validation/${btoa(JSON.stringify({dato:formik.values.username,value:formik.values.idEstado}))}`)}
            variant="body1"
            sx={{ textDecoration: "none" }}
          >
            Cambiar contraseña aquí.
          </Typography>
        </div>
        <Typography
          variant="body2"
          sx={{ textAlign: "center", color: "#9a9a9a",mt:1 }}
        >
          Acceso a personal autorizado
        </Typography>
      </div>
    </>
  );
};

const CardAction = ({ signIn, loading }) => {
  let navigate = useNavigate();
  return (
    <>
      <div className="card-action">
        {loading ? (
          <CircularProgress
            sx={{
              display: "flex",
              margin: "0 auto",
              width: "30px !important",
              height: "30px !important",
            }}
          />
        ) : (
          <Button className="button-login" onClick={signIn}>
            INICIAR SESIÓN
          </Button>
        )}
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            color: "#9a9a9a",
            marginBottom: "5px",
          }}
        >
          {Version ? Version : "1.0.0"}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/*    <a
            style={{ color: "#0041a0" }}
            target="_blank"
            rel="noreferrer"
            href="https://espormexico.com/privacidad"
          >
            Ver aviso de privacidad
          </a> */}
        </Typography>
        <br />
        <Divider />
        <Typography
          component={Link}
          onClick={()=>navigate("/registro")}
          variant="subtitle1"
          sx={{ textDecoration: "none" }}
        >
          Si aún no tienes cuenta, Regístrate Aquí.
        </Typography>
      </div>
    </>
  );
};

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  // const captchaRef = useRef(null);
  const navigate = useNavigate();

  const initialValues = {
    username: "",
    password: "",
    idEstado: 0,
    type_login: "web",
  };

  const validationSchema = Yup.object({
    username: Yup.string().required().label("Usuario"),
    password: Yup.string().required().min(6).label("Contraseña"),
    idEstado: Yup.number().required().min(1).label("Entidad"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      //signIn(values);
      onChangeCaptcha(values);
    },
  });

  const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formik.submitForm();
    }
  };

  const onChangeCaptcha = useCallback(
    (values) => {
      if (!executeRecaptcha) {
        return;
      }

      executeRecaptcha("login")
        .then((token) => {
          const data = {
            ...values,
            token: token,
          };
          signIn(data);
        })
        .catch((error) => Swal.fire({ title: error, icon: "warning" }));
    },
    // eslint-disable-next-line
    [executeRecaptcha]
  );

  const signIn = (values) => {
    // const token = captchaRef.current.getValue()
    setLoading(true);
    ApiExec({ ...values }, "auth/login")
      .then(function (res) {
        return res;
      })
      .then((token) => {
        if (token.results) {
          setVars("Token", token.response);
          navigate(token.response.user.DefaultPage);
        } else {
          Swal.fire({ title: token.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({
          title: e.message,
          icon: "warning",
        });
      })
      .finally((end) => {
        setLoading(false);
        // captchaRef.current.reset();
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="section-image"
        sx={{
          backgroundImage: `url(${require("@assets/img/back02.png")})`,
        }}
      >
        <Container component="main" maxWidth="sm">
          <Box className="login-container">
            <Card className="card-login">
              <CardHeader />
              <CardBody
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                formik={formik}
                handleOnKeyPress={handleOnKeyPress}
                // captchaRef={captchaRef}
                //onChangeCaptcha={onChangeCaptcha}
              />
              <CardAction signIn={formik.submitForm} loading={loading} />
            </Card>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

const LoginSian = () => {
  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
        <Login />
      </GoogleReCaptchaProvider>
    </>
  );
};

export default LoginSian;
