import { PrivilegedRoute, ProtectedRoute } from "@middlewares";

import Compromisos from "@pages/Compromisos";
import CompromisosExtranjero from "@pages/Compromisos/CompromisosExtranjero";
import CompromisosExtranjeroAdd from "@pages/Compromisos/CompromisosExtranjeroAdd";
import RegisterForm from "@pages/Compromisos/RegisterForm";
import FrenteAmplioRegisterForm from "@pages/Compromisos/FrenteAmplioRegisterForm";
import History from "@pages/Compromisos/History";
import CompromisosTypeRegister from "@pages/Compromisos/CompromisosTypeRegister";
import HistoryForeigner from "@pages/Compromisos/HistoryForeigner";

const CompromisosRoutes = [
  {
    path: "/compromisos",
    element: (
      <PrivilegedRoute>
        <Compromisos />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/compromisos_ext",
    element: (
      <PrivilegedRoute>
        <CompromisosExtranjero />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/historial-captura",
    element: (
      <PrivilegedRoute>
        <History />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/compromisos-tipo-registro",
    element: (
      <ProtectedRoute>
        <CompromisosTypeRegister />
      </ProtectedRoute>
    ),
  },
  {
    path: "/compromisos-registro",
    element: (
      <PrivilegedRoute>
        <RegisterForm />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/frenteamplio-registro",
    element: (
      <PrivilegedRoute>
        <FrenteAmplioRegisterForm />
      </PrivilegedRoute>
    ),
  },
  {
    path: "/compromisos-ext-registro",
    element: (
      <ProtectedRoute>
        <CompromisosExtranjeroAdd />
      </ProtectedRoute>
    ),
  },
  {
    path: "/historial-ext-captura",
    element: (
      <ProtectedRoute>
        <HistoryForeigner />
      </ProtectedRoute>
    ),
  },
];

export default CompromisosRoutes;
