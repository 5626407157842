import { WhatsApp } from "@mui/icons-material";
import { Icon } from "@mui/material";

//const { REACT_APP_BASE_URL } = process.env;
//export const LINK_API = REACT_APP_BASE_URL;
export const LINK_API = "http://127.0.0.1:8788";

export const COLUMNS_OPERATION = [
  {
    id: "idDF",
    label: "DF",
  },
  { id: "idDL", label: "DL" },
  { id: "Municipio", label: "MUNICIPIO" },
  { id: "Seccion", label: "SECCIÓN" },
  { id: "NombreCasilla", label: "CASILLA" },
  { id: "Conectividad", label: "CONECTIVIDAD" },
  { id: "TipoTransmision", label: "TIPO DE TRANSMISIÓN" },
  { id: "tieneFoto", label: "FOTO" },
  { id: "HoraPaseListaRG", label: "ASIST.RG" },
  { id: "HoraPaseListaP1", label: "RCP1" },
  { id: "HoraPaseListaP2", label: "RCP2" },
  { id: "HoraPaseListaS1", label: "RCS1" },
  { id: "HoraPaseListaS2", label: "RCS2" },
  { id: "HoraInicioInstalacion", label: "INSTALACIÓN" },
  { id: "HoraAperturaCasilla", label: "APERTURA" },
  { id: "HoraCierreCasilla", label: "CIERRE CASILLA" },
  { id: "HoraCierreVotacion", label: "CIERRE VOTACIÓN" },
  { id: "TotalIncidencias", label: "INCIDENCIA", align: "center" },
];

export const COLUMNS_APERTURA = [
  {
    id: "idDF",
    label: "DF",
  },
  { id: "idDL", label: "DL" },
  { id: "Municipio", label: "MUNICIPIO" },
  { id: "Seccion", label: "SECCIÓN" },
  { id: "NombreCasilla", label: "CASILLA" },
  { id: "Conectividad", label: "CONECTIVIDAD" },
  { id: "TipoTransmision", label: "TIPO DE TRANSMISIÓN" },
  { id: "tieneFoto", label: "FOTO" },
  { id: "RCP1", label: "RCP1" },
  { id: "RCP2", label: "RCP2" },
  { id: "RCS1", label: "RCS1" },
  { id: "RCS2", label: "RCS2" },
  { id: "HoraInicioInstalacion", label: "INSTALACIÓN" },
  { id: "HoraAperturaCasilla", label: "APERTURA" },
];

export const COLUMNS_CIERRE = [
  {
    id: "idDF",
    label: "DF",
  },
  { id: "idDL", label: "DL" },
  { id: "Municipio", label: "MUNICIPIO" },
  { id: "Seccion", label: "SECCIÓN" },
  { id: "NombreCasilla", label: "CASILLA" },
  { id: "Conectividad", label: "CONECTIVIDAD" },
  { id: "TipoTransmision", label: "TIPO DE TRANSMISIÓN" },
  { id: "tieneFoto", label: "FOTO" },
  { id: "RCP1", label: "RCP1" },
  { id: "RCP2", label: "RCP2" },
  { id: "RCS1", label: "RCS1" },
  { id: "RCS2", label: "RCS2" },
  { id: "HoraCierreCasilla", label: "CIERRE CASILLA" },
  { id: "HoraCierreVotacion", label: "CIERRE VOTACIÓN" },
];

export const COLUMNS_INCIDENTS = [
  { id: "Municipio", label: "MUNICIPIO" },
  { id: "Seccion", label: "SECCIÓN" },
  { id: "NombreCasilla", label: "CASILLA" },
  { id: "HoraIncidencia", label: "HORA INCIDENCIA" },
  { id: "Incidencia", label: "INCIDENCIA" },
  { id: "Resuelto", label: "SOLUCIONADO" },
  { id: "HoraSolucion", label: "HORA SOLUCIÓN" },
  { id: "tieneFoto", label: "FOTO" },
  { id: "Estatus", label: "ESTATUS" },
];

export const CAT_STATUS = [
  { value: 1, label: "Nueva" },
  { value: 2, label: "En proceso" },
  { value: 3, label: "Atendido" },
];

export const CAT_SOLVED = [
  { value: 9999, label: "Todos" },
  { value: 0, label: "NO" },
  { value: 1, label: "SI" },
];

export const ACTIONS_SPEED = [
  { icon: <Icon>smartphone</Icon>, name: "App", type: "app" },
  { icon: <Icon>call</Icon>, name: "Teléfono", type: "telefono" },
  { icon: <WhatsApp />, name: "Whatsapp", type: "whatsapp" },
  { icon: <Icon>edit_square</Icon>, name: "Papel", type: "papel" },
];

export const COLUMNS_REPORTS = [
  {
    id: "Region",
    label: "Región",
  },
  { id: "Municipio", label: "Municipio" },
  { id: "DistritoFederal", label: "Distrito Federal" },
  { id: "DistritoLocal", label: "Distrito Local" },
  { id: "Seccion", label: "Sección" },
  { id: "NombreCasilla", label: "Nombre Casilla" },
  { id: "Casillas", label: "Casillas" },
  { id: "AsistenciaP1", label: "Asistencia P1" },
  { id: "PorcentajeAbiertoP1", label: "% Abierto" },
  { id: "NoAsistenciaP1", label: "No. Asitencia P1" },
  { id: "PorcentajeNoAbiertoP1", label: "% No Abierto" },
  { id: "AsistenciaP2", label: "Asistencia P2" },
  { id: "PorcentajeAbiertoP2", label: "% Abierto" },
  { id: "NoAsistenciaP2", label: "No. Asitencia P2" },
  { id: "PorcentajeNoAbiertoP2", label: "% No Abierto" },
  { id: "AsistenciaS1", label: "Asistencia S1" },
  { id: "PorcentajeAbiertoS1", label: "% Abierto" },
  { id: "NoAsistenciaS1", label: "No. Asitencia S1" },
  { id: "PorcentajeNoAbiertoS1", label: "% No Abierto" },
  { id: "AsistenciaS2", label: "Asistencia S2" },
  { id: "PorcentajeAbiertoS2", label: "% Abierto" },
  { id: "NoAsistenciaS2", label: "No. Asitencia S2" },
  { id: "PorcentajeNoAbiertoS2", label: "% No Abierto" },
];

export const COLUMNS_DIRECTORY = [
  { id: "DFED", label: "DF" },
  { id: "DLOC", label: "DL" },
  { id: "Seccion", label: "SECCIÓN" },
  { id: "Municipio", label: "MUNICIPIO" },
  { id: "NombreRuta", label: "RUTA" },
  { id: "NombreCasilla", label: "CASILLA" },
  { id: "NombreRG", label: "RG" },
  { id: "NombreRCP1", label: "RCP1" },
  { id: "NombreRCP2", label: "RCP2" },
  { id: "NombreRCS1", label: "RCS1" },
  { id: "NombreRCS2", label: "RCS2" },
  { id: "PaseListaRC", label: "PASE LISTA RC" },
  { id: "PaseListaRG", label: "PASE LISTA RG" },
];

export const PROGRESS_KEYS = [
  "PorcentajeAbiertoP1",
  "PorcentajeAbiertoP2",
  "PorcentajeAbiertoS1",
  "PorcentajeAbiertoS2",
  "PorcentajeNoAbiertoP1",
  "PorcentajeNoAbiertoP2",
  "PorcentajeNoAbiertoS1",
  "PorcentajeNoAbiertoS2",
];
