import React, { useState } from "react";
import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@src/theme/index";
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
// import Footer from "./Footer";

const AdminLayout = (props) => {
  const { children } = props;
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const handleToggle = (open) => {
    if (open !== undefined) {
      setSidebarOpen(open);
    } else {
      setSidebarOpen((prevOpen) => !prevOpen);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <CssBaseline />
        <AdminNavbar handleToggle={handleToggle} title={"Inicio"} />
        <AdminSidebar handleToggle={handleToggle} open={isSidebarOpen} />
        <Box sx={{ pt: 10, ml: 8, mt: 8, p: 2, mb: 5 }}>{children}</Box>
        {/* <Box sx={{ ml: '50px' }}>
          <Footer />
        </Box> */}
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(AdminLayout);
