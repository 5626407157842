import React, { useState, useEffect, useCallback, useContext } from "react";
import { Card, CardContent } from "@mui/material";
import CompromisosService from "@services/MapServices/CompromisoServices";
import Swal from "sweetalert2";
import Map from "@components/Maps/CustomComponents/Compromisos";
import { setVars, getVars } from "@utils/global";
import Filter from "@components/MapsModules/Compromisos/FilterTabs";
import CatalogService from "@services/CatalogServices";
import national from "@services/MapServices/NationalServices";
import ReportsContext from "@components/Reports/ReportsContext"

const elements = {
  manzana: [
    {
      title: "Manzana:",
      name: "Manzana",
      type: "text",
    },
    {
      title: "Prioridad:",
      name: "PrioridadLabel",
      type: "text",
    },
  ],
  seccion: [
    {
      title: "Seccion:",
      name: "seccion",
      type: "text",
    },
    {
      title: "DLOC:",
      name: "DLOC",
      type: "text",
    },
    {
      title: "DFED:",
      name: "DFED",
      type: "text",
    },
    {
      title: "Meta:",
      name: "MetaCompromisos",
      type: "text",
    },
    {
      title: "Av Esp:",
      name: "AvanceEsperado",
      type: "text",
    },
    {
      title: "Av Comp:",
      name: "AvanceCompromisos",
      type: "text",
    },
    {
      title: "Av Comp Únicos:",
      name: "AvanceCompromisosUnicos",
      type: "text",
    },
    {
      title: "% Comp Únicos:",
      name: "PorcentajeAvanceCompromisosUnicos",
      type: "text",
    },
    {
      title: "LNOM:",
      name: "Lnom",
      type: "text",
    },
  ],
  dfed: [
    {
      title: "DFED:",
      name: "dfed",
      type: "text",
    },
  ],
};
const Maps = (props) => {
  const {
    handleLoading
  } = useContext(ReportsContext);

  const { best, worst } = props;
  const { user } = getVars("Token");
  const [polygons, setPolygons] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [polygonsBlocks, setPolygonsBlocks] = useState({
    type: "FeatureCollection",
    features: [],
  });

  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);
  const [valuesInfoGeneral, setValuesInfoGeneral] = useState(null);
  const [catalogs, setCatalogs] = useState({
    regiones: [],
    municipios: [],
  });
  const [shpInfoGeneral, setShpInfoGeneral] = useState('Entidad');
  const [loadedLayer, setLoadedLayer] = useState(false);

  const handleFilterExect = (_filter, polygon) => {
    let _filterModified = _filter.map(item => {
      const regex = /tb/gi;
      let itemModified = item
      itemModified.id = item.id.replace(regex, 'cat_secciones')
      return itemModified
    });
    let data_parameters = {};
    if (!polygon)
      data_parameters = {
        page: 0,
        pageSize: 10,
        filtered: _filterModified,
      };
    else
      data_parameters = {
        polygon,
        page: 0,
        pageSize: 10,
        filtered: _filterModified,
      };
    setPolygons(null);
    setPolygonsBlocks(null);
    CompromisosService.getSections(data_parameters)
      .then((res) => {
        if (res.results) {
          setPolygons(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });

    let lastElement = _filterModified.slice(-1);
    _filterModified = lastElement;
    let shp = 'entidad';
    setShpInfoGeneral('Entidad');
    const regex = /cat_secciones/gi;
    switch (_filterModified[0].id) {
      case 'cat_secciones.idMunicipio':
        shp = 'municipio'; setShpInfoGeneral('Municipio')
        _filterModified[0].id = _filterModified[0].id.replace(regex, 'estad_municipios');
        break;
      case 'cat_secciones.idPoligono':
        shp = 'poligono'; setShpInfoGeneral('Poligono')
        _filterModified[0].id = _filterModified[0].id.replace(regex, 'estad_poligonos');
        break;
      case 'cat_secciones.Seccion':
        shp = 'seccion'; setShpInfoGeneral('Seccion')
        _filterModified[0].id = _filterModified[0].id.replace(regex, 'estad_secciones');
        break;
      case 'cat_secciones.idDF':
        shp = 'dfed'; setShpInfoGeneral('DFED');
        _filterModified[0].id = _filterModified[0].id.replace(regex, 'estad_df');
        break;
      case 'cat_secciones.idDL':
        shp = 'dloc'; setShpInfoGeneral('DLOC');
        _filterModified[0].id = _filterModified[0].id.replace(regex, 'estad_dl');
        break;
      default:
        shp = 'entidad'; setShpInfoGeneral('Entidad');
    }
    CompromisosService.getInformationGeneral({ page: 0, pageSize: 10, filtered: _filterModified, shp })
      .then((res) => {
        if (res.results) {

          if (res.response.data.length !== 0) {
            setValuesInfoGeneral(res.response.data[0]);
          }
          else {
            Swal.fire({ title: res.message, icon: "warning" });
          }

        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  };

  const loadCatalogs = useCallback(() => {
    let catalogsParams = [{ id: "entidades", getAll: false }];
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          if (res.response.errors.length > 0) {
            Swal.fire({
              title:
                "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
              icon: "warning",
            });
          }

          const catalogsResponse = res.response.catalogs;
          setCatalogs((prevState) => {
            return {
              ...prevState,
              entidades: catalogsResponse.entidades
                ? [{ value: 0, label: "TODAS" }].concat(catalogsResponse.entidades)
                : [],
            };
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatalogs(false);
      });
  }, []);

  useEffect(() => {
    const init = async () => {
      getEmptyMap();
      setVars("filterSavedMap", {
        idEntidad: "",
        idMunicipio: "",
        idPoligono: "",
        Seccion: ""
      });
      // loadCatalogs();
      await loadMainLayer();
    }

    init();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {

    if (loadedLayer) {
      const _best = best.map(item => parseInt(item.idEntidad));
      const _worst = worst.map(item => parseInt(item.idEntidad));

      setPolygons(() => {
        return {
          ...polygons,
          features: polygons.features.map(row => {
            const id = parseInt(row.id);
            return {
              ...row,
              properties: {
                ...row.properties,
                line_color: setLineColor(id, _best, _worst),
                color: setColor(id, _best, _worst),
              }
            };
          }),
        }
      })
    }
    // eslint-disable-next-line
  }, [loadedLayer, best, worst])

  const getEmptyMap = () => {
    setPolygons({
      type: "FeatureCollection",
      features: [],
    });
  };

  const loadMainLayer = async () => {
    handleLoading(true);
    try {
      const params = {
        color: 0,
        page: 0,
        pageSize: 10,
        filtered: [
          {
            filter: "IN",
            id: "tb.idEntidad",
            value: 0
          }
        ],
        shp: "entidad",
        idEntidad: 0
      }

      const result = await national.getShapes(params);

      const { results, response, message } = result;

      if (results) {
        setPolygons(response.data);
      } else {
        Swal.fire({
          title: message,
          icon: 'warning'
        });
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: 'warning'
      });
    } finally {
      handleLoading(false);
      setLoadedLayer(true);
    }
  }

  const FilterComponent = (
    <Filter
      catalogs={catalogs}
      loadingCatalogs={isLoadingCatalogs}
      entidad={user.idEstado}
      handleFilter={handleFilterExect}
    />
  );

  const handleViewDetail = async (e) => {

  };

  const setColor = (id, best, worst) => {
    if (best.includes(id)) {
      return "#1a237e";
    } else if (worst.includes(id)) {
      return "#c62828";
    }

    return "#fff8e1";
  }

  const setLineColor = (id, best, worst) => {
    if (best.includes(id)) {
      return "#2196f3";
    } else if (worst.includes(id)) {
      return "#c62828";
    }

    return "#666";
  }

  return (
    <Card
      sx={{
        boxShadow: { xs: 0, sm: 1 },
        borderRadius: 2,
      }}
    >
      <CardContent
        sx={{
          padding: { xs: 0, sm: 2 },
          paddingBottom: { xs: "0 !important", sm: "16px !important" },
        }}
      >
        <Map
          zoom={6}
          position={[23.220226, -101.229282]}
          data={polygons}
          sub_data={polygonsBlocks}
          CardComponentFlag={false}
          CardComponent={
            <></>
          }
          TitleCardComponent={""}
          CardInfoFlag={true}
          CardInfoInitialValues={{
            title: "INFORMACIÓN",
            elements: elements[polygons ? polygons.shp : "dfed"],
          }}
          handleClickViewDetailEvent={handleViewDetail}
          FilterComponentFlag={false}
          FilterComponent={FilterComponent}
          flagInfoGeneral={true}
          valuesInfoGeneral={valuesInfoGeneral}
          initialValuesInfoGeneral={{
            title: "RESUMEN",
            elements: [
              {
                title: "center",
                name: shpInfoGeneral,
                color: "#000",
                type: "text",
              },
              {
                title: "Meta:",
                name: "MetaEstructura",
                color: "#000",
                type: "text",
              },
              {
                title: "Av Estructura:",
                name: "AvanceEstructura",
                color: "#000",
                type: "text",
              },
              {
                title: "% Av Estructura:",
                name: "PorcentajeAvanceEstructura",
                color: "#000",
                type: "percent",
              },
            ],
          }}
        ></Map>
      </CardContent>
    </Card>
  );
};
export default Maps;
