import React, { useEffect, useState } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import CardCommitments from "@components/Card/StatesCard";
import CardStats from "@components/Card/CardStats";
import BasicTable from "@components/Tables/BasicTable";
import electoral from "@services/ElectoralServices";
import Swal from "sweetalert2";
import FilterResponsable from "@components/Electoral/FilterResponsable";
import AttendanceCard from "@components/Attendance/AttendanceCard";
import { numberWithCommas } from '@utils/Utilities';
import { roundTo } from "@components/Maps/Auxiliars/MathFunctions";
import { CardContent, Container, Grid, Card } from "@mui/material";

const AvanceResponsables = () => {
  //variables
  let today = new Date();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState({
    page: 0,
    pageSize: 10,
    filtered: [],
  });
  const [stadsR, setStadsR] = useState({
    page: 0,
    pageSize: 999999999999,
    filtered: [],
  });
  const [loading, setLoading] = useState(false);
  const [dataCard,setDataCard] = useState({
    "TotalMeta": 0,
    "TotalAvance": 0,
    "PorcentajeAvance": "0.22"
  })
  const [dataCardR,setDataCardR] = useState({
    "rc": {
        "TotalGeneral": "774",
        "idMunicipioReportes": 1,
        "TotalMeta": "31692"
    },
    "representantes": {
        "TotalGeneral": 351,
        "idMunicipioReportes": 48,
        "TotalMeta": 0
    },
    "rg": {
        "TotalGeneral": "342",
        "idMunicipioReportes": 1,
        "TotalMeta": "1107"
    }
})
  const columns = [
    { id: "Municipio", label: "Municipio", columnAction: false, width: 150 },
    { id: "Perfil", label: "Responsabilidad", columnAction: false, width: 150 },
    { id: "Meta", label: "Meta", columnAction: false, width: 150 },
    { id: "Avance", label: "Avance", columnAction: false, width: 150 },
    { id: "PorcentajeAvance", label: "PorcentajeAvance", columnAction: false, width: 150 },
  ];

  //effets

  useEffect(() => {
    getData();
  }, [tableData]);

  // funciones

  const getData = () => {
    if (loading) {
      return true;
    }
    setLoading(true);
    electoral
      .getListCoordinators(tableData)
      .then((res) => {
        if (res.results) {
          setData(res.response.data);
          setTotal(res.response.total);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });
      setLoading(true);
      electoral
      .getStatsResponsables({...tableData,page:0,pageSize:999999999999})
      .then((res) => {
        if (res.results) {
          setDataCard({...res.response.data,
            TotalAvance: res.response.data.TotalAvance === null ? 0 : res.response.data.TotalAvance,
            TotalMeta: res.response.data.TotalMeta === null ? 0 : res.response.data.TotalMeta
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });

      setLoading(true);
      electoral
      .getStatsRs(stadsR)
      .then((res) => {
        if (res.results) {
          setDataCardR({...res.response.data,
          representantes:{
            "S":res.response.data.representantes.find(item => item.Tipo === 2) ? res.response.data.representantes.find(item => item.Tipo === 2).TotalGeneral:0,
            "P":res.response.data.representantes.find(item => item.Tipo === 1) ? res.response.data.representantes.find(item => item.Tipo === 1).TotalGeneral:0,
            "SS":res.response.data.representantes.find(item => item.Tipo === 4)? res.response.data.representantes.find(item => item.Tipo === 4).TotalGeneral:0,
            "PP":res.response.data.representantes.find(item => item.Tipo === 3) ? res.response.data.representantes.find(item => item.Tipo === 3).TotalGeneral:0,
          }
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setLoading(false);
      });

  };

  const handleChangePagination = (pagination) => {
    setTableData({ ...tableData, ...pagination });
  };

  const handleChangeFilter = (type) => {
    const {
      Region,
      DF,
      Responsabilidad,
      MunicipioDL,
      Celular,
      NombreCompleto,
    } = type;

    let filtered = [];
    let filtro = [];

    if (Region > 0) {
      filtered = [
        ...filtered,
        { id: "cat_municipios_reportes.idRegion", filter: "=", value: Region },
      ];

      filtro=[
        ...filtered,
        { id: "cat_municipios_reportes.idRegion", filter: "=", value: Region },
      ];

    }
 /*    if (DF > 0) {
      filtered = [
        ...filtered,
        { id: "estad_meta_electoral_df.idDF", filter: "=", value: DF },
      ];

      filtro=[
        ...filtered,
        { id: "estad_meta_electoral_df.idDF", filter: "=", value: DF },
      ];

    } */
    if (MunicipioDL > 0) {
      filtered = [
        ...filtered,
        { id: "cat_municipios_reportes.id", filter: "=", value: MunicipioDL },
      ];

      filtro=[
        ...filtered,
        { id: "cat_municipios_reportes.id", filter: "=", value: MunicipioDL },
      ];
    }
    if (Responsabilidad > 0) {
      filtered = [
        ...filtered,
        {
          id: "estad_meta_electoral.idPerfil",
          filter: "=",
          value: Responsabilidad,
        },
      ];

   /*    filtro = [
        ...filtro,
        {
          id: "usuarios_ambito_perfil.AceptaCuidarOtra",
          filter: "=",
          value: 1,
        },
      ]; */
    }

    setTableData({
      ...tableData,
      page:0,
      filtered: filtered,
    });

    setStadsR({...stadsR,
      filtered: filtro
    })

    
  };

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader title={"Avance Estructura"} />
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card>
            <CardContent>
            <FilterResponsable df={false} onChangeFilter={(e)=>handleChangeFilter(e)} filterData={false} leonAll={true} />
            </CardContent>
        </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
        <AttendanceCard
              data={{
                contentData: String(roundTo(dataCard.PorcentajeAvance,2)),
                leftFooterData: String(numberWithCommas(dataCard.TotalAvance)),
                rightFooterData: String(numberWithCommas(dataCard.TotalMeta)),
              }}
              config={{
                leftFooterTitle: "Avance",
                rightFooterTitle: "Meta",
              }}
              loading ={loading}
              titlecontent={"Responsables"}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
     <CardCommitments
              icon={"groups"}
              title={"RC´S"}
              total={`${numberWithCommas(dataCardR.rc.TotalGeneral)} ${dataCardR.rc.TotalMeta ? `de ${numberWithCommas(dataCardR.rc.TotalMeta)}`: "de 0"}`}
              subtitle1={"S1"}
              subtotal1={numberWithCommas(dataCardR.representantes["S"])}
              subtitle2={"S2"}
              subtotal2={numberWithCommas(dataCardR.representantes["SS"])}
              subtitle3={"P1"}
              subtotal3={numberWithCommas(dataCardR.representantes.P)}
              subtitle4={"P2"}
              subtotal4={numberWithCommas(dataCardR.representantes.PP)}
              subIcon={"update"}
              subFooter={today.toLocaleDateString()}
              loading={loading}
            /> 
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
        <CardStats
              sx={{height:"209px",display:"flex", justifyContent:"center",textAlign:"center",alignItems:"center"}}
              icon={"groups"}
              title={"RG´S"}
              total={`${numberWithCommas(dataCardR.rg.TotalGeneral)} de ${numberWithCommas(dataCardR.rg.TotalMeta)}`}
              subIcon={"sync"}
              subtitle={today.toLocaleDateString()}
              loading={loading}
            />
        </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardContent>
                <BasicTable
                  rows={data}
                  hcolumns={columns}
                  pageProp={tableData.page}
                  pageSize={tableData.pageSize}
                  total={total}
                  handleChangePagination={handleChangePagination}
                  isLoading={loading}
                  stickyHeader={true}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </AdminLayout>
  );
};
export default AvanceResponsables;
