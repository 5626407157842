import React, { useState, useEffect } from "react";
import { Grid, Button, Stack } from "@mui/material";
import BasicSelect from "@components/Selects/BasicSelect";
import { setVars, getVars } from "@utils/global";
const Filter = ({ catalogs, loadingCatalogs, handleFilter, dfed, dloc}) => {
  const [catDLOC, setCatDLOC] = useState([]);
  const [catDFED, setCatDFED] = useState([]);
  const [filter, setFilter] = useState({
    idDF: "",
    idDL: "",
  });
  const getCatalogsWithStored = () => {
    const filterSavedMapDFED = getVars("filterSavedMapDFED",[]);
    const filterSavedMapDLOC = getVars("filterSavedMapDLOC", []);
    setCatDFED(filterSavedMapDFED);
    setCatDLOC(filterSavedMapDLOC);
  }
  useEffect(
    () => {
      let filter_saved = getVars("filterSavedMap", {
        idDF: "",
        idDL: ""
      });
      if (
        filter_saved.idDF !== "" ||
        filter_saved.idDL !== "" 
      ) {
        setFilter(filter_saved);
        getCatalogsWithStored();
          
      }
    },
    // eslint-disable-next-line
    []
  );

  const handleChangeDFED = (e) => {
    setVars("flagCreatePolygon",false);
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeDLOC = (e) => {
    setVars("flagCreatePolygon",false);
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };
  const handleClick = () => {
    let filter_data = [];
   
    if(filter.idDF !== ""){
      filter_data.push({id:"cat_secciones.idDF",
      filter: filter.idDF === 0 ? "IN" : "=",
      value: filter.idDF === 0 ? [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15] :filter.idDF});
    }
    if(filter.idDL !== ""){
      filter_data.push({id:"cat_secciones.idDL", 
      filter: filter.idDL === 0 ? "IN" : "=",
       value: filter.idDL === 0 ? [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22] :filter.idDL});
    } 
    if(filter.idDL !== "" || filter.idDF !== ""){
      setVars("flagCreatePolygon",true);
    }

    handleFilter(filter_data);
    
    setVars("filterSavedMapDFED", catDFED);
    setVars("filterSavedMapDLOC", catDLOC);
    setVars("filterSavedMap", {
      idDF: filter.idDF,
      idDL: filter.idDL
    });
  };
  return (
    <>
      <Grid container rowSpacing={1} style={{ width: "300px" }}>
        {dfed? 
        <Grid item sm={12}>
          <BasicSelect
            name="idDF"
            label="DFED *"
            options={catalogs.df}
            value={filter.idDF}
            onChange={handleChangeDFED}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid>
        :<></>}
        {dloc? 
        <Grid item sm={12}>
          <BasicSelect
            name="idDL"
            label="DLOC *"
            options={catalogs.dl}
            value={filter.idDL}
            onChange={handleChangeDLOC}
            isLoading={loadingCatalogs}
            sx={{ width: "100%" }}
          />
        </Grid>
        :<></>}
      </Grid>
      <br></br>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Button
          size="small"
          style={{ borderRadius: "20px", alignSelf: "center" }}
          variant="outlined"
          disabled={(filter.idDF === "" && filter.idDL === "")?true:false}
          onClick={handleClick}
        >
          Filtrar
        </Button>
      </Stack>
    </>
  );
};
export default Filter;
