import React, { useState, useEffect, useCallback } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import { Container } from "@mui/material";
import CompromisosService from "@services/MapServices/CompromisoServices";
import Swal from "sweetalert2";
import Map from "@components/Maps/CustomComponents/Compromisos";
import { setVars, getVars } from "@utils/global";
import Filter from "@components/MapsModules/Compromisos/FilterTabs";
import CatalogService from "@services/CatalogServices";
const elements = {
  manzana: [
    {
      title: "Manzana:",
      name: "Manzana",
      type: "text",
    },
    {
      title: "Prioridad:",
      name: "PrioridadLabel",
      type: "text",
    },
  ],
  seccion: [
    {
      title: "Seccion:",
      name: "seccion",
      type: "text",
    },
    {
      title: "DLOC:",
      name: "DLOC",
      type: "text",
    },
    {
      title: "DFED:",
      name: "DFED",
      type: "text",
    },
    {
      title: "Meta:",
      name: "MetaCompromisos",
      type: "text",
    },
    {
      title: "Av Esp:",
      name: "AvanceEsperado",
      type: "text",
    },
    {
      title: "Av Comp:",
      name: "AvanceCompromisos",
      type: "text",
    },
    {
      title: "Av Comp Únicos:",
      name: "AvanceCompromisosUnicos",
      type: "text",
    },
    {
      title: "% Comp Únicos:",
      name: "PorcentajeAvanceCompromisosUnicos",
      type: "text",
    },
    {
      title: "LNOM:",
      name: "Lnom",
      type: "text",
    },
  ],
  dfed: [
    {
      title: "DFED:",
      name: "dfed",
      type: "text",
    },
  ],
};
const Maps = () => {
  const { user } = getVars("Token");
  const [polygons, setPolygons] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [polygonsBlocks, setPolygonsBlocks] = useState({
    type: "FeatureCollection",
    features: [],
  });
  
  const [isLoadingCatalogs, setIsLoadingCatalogs] = useState(true);
  const [valuesInfoGeneral, setValuesInfoGeneral] = useState(null);
  const [catalogs, setCatalogs] = useState({
    regiones: [],
    municipios: [],
  });
  const [shpInfoGeneral, setShpInfoGeneral] =  useState('Entidad');

  const getEmptyMap = () => {
    setPolygons({
      type: "FeatureCollection",
      features: [],
    });
  };

  const handleFilterExect = (_filter, polygon) => {
    let _filterModified =  _filter.map(item => {
      const regex = /tb/gi;
      let itemModified = item
      itemModified.id = item.id.replace(regex, 'cat_secciones')
      return itemModified
    });
    let data_parameters = {};
    if(!polygon)
      data_parameters = {
        page: 0,
        pageSize: 10,
        filtered: _filterModified,
      };
    else
    data_parameters = {
      polygon,
      page: 0,
      pageSize: 10,
      filtered: _filterModified,
    };
    setPolygons(null);
    setPolygonsBlocks(null);
    CompromisosService.getSections(data_parameters)
      .then((res) => {
        if (res.results) {
          setPolygons(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
      
      let lastElement = _filterModified.slice(-1);
      _filterModified = lastElement;
      let shp = 'entidad';
      setShpInfoGeneral('Entidad');
      const regex = /cat_secciones/gi;
      switch(_filterModified[0].id){
        case 'cat_secciones.idMunicipio':
          shp = 'municipio'; setShpInfoGeneral('Municipio')
          _filterModified[0].id = _filterModified[0].id.replace(regex, 'estad_municipios');
          break;
        case 'cat_secciones.idPoligono':
          shp = 'poligono'; setShpInfoGeneral('Poligono')
          _filterModified[0].id = _filterModified[0].id.replace(regex, 'estad_poligonos');
          break;
        case 'cat_secciones.Seccion':
          shp = 'seccion'; setShpInfoGeneral('Seccion')
          _filterModified[0].id = _filterModified[0].id.replace(regex, 'estad_secciones');
          break;
        case 'cat_secciones.idDF':
          shp = 'dfed'; setShpInfoGeneral('DFED');
          _filterModified[0].id = _filterModified[0].id.replace(regex, 'estad_df');
          break;
        case 'cat_secciones.idDL':
          shp = 'dloc'; setShpInfoGeneral('DLOC');
          _filterModified[0].id = _filterModified[0].id.replace(regex, 'estad_dl');
          break;
        default:
          shp = 'entidad'; setShpInfoGeneral('Entidad');
      }
      CompromisosService.getInformationGeneral({page:0, pageSize:10, filtered:_filterModified, shp})
      .then((res) => {
        if (res.results) {
          
          if(res.response.data.length!==0){
            setValuesInfoGeneral(res.response.data[0]);
          }
          else {
            Swal.fire({ title: res.message, icon: "warning" });
          }
          
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });
  };

  const loadCatalogs = useCallback(() => {
    let catalogsParams = [{ id: "entidades", getAll:false }];
    CatalogService.getCatalogs(catalogsParams)
      .then((res) => {
        if (res.results) {
          if (res.response.errors.length > 0) {
            Swal.fire({
              title:
                "Algunos catálogos NO pudieron ser cargados. Contacte al administrador",
              icon: "warning",
            });
          }

          const catalogsResponse = res.response.catalogs;
          setCatalogs((prevState) => {
            return {
              ...prevState,
                entidades: catalogsResponse.entidades
                ? [{value: 0, label: "TODAS"}].concat(catalogsResponse.entidades)
                : [],
            };
          });
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      })
      .finally(() => {
        setIsLoadingCatalogs(false);
      });
  }, []);
  useEffect(() => {
    setVars("filterSavedMap", {
      idEntidad: "",
      idMunicipio: "",
      idPoligono: "",
      Seccion: ""
    })
    getEmptyMap();
    loadCatalogs();
    // eslint-disable-next-line
  }, []);
  const FilterComponent = (
    <Filter
      catalogs={catalogs}
      loadingCatalogs={isLoadingCatalogs}
      entidad={user.idEstado}
      handleFilter={handleFilterExect}
    />
  );
  const handleViewDetail = (e) => {
    let data_parameters = {
      shp:"seccion",
      page: 0,
      pageSize: 10,
      filtered: [{id:"cat_secciones.Seccion", filter:"=", value: e.Seccion}],
    };
    setPolygonsBlocks(null);
    CompromisosService.getBlocks(data_parameters)
      .then((res) => {
        if (res.results) {
          setPolygonsBlocks(res.response.data);
        } else {
          Swal.fire({ title: res.message, icon: "warning" });
        }
      })
      .catch((e) => {
        Swal.fire({ title: e.message, icon: "warning" });
      });

      const data_parameters_secc = {
        color: 0,
        page: 0,
        pageSize: 10,
        filtered: [{id:"cat_secciones.Seccion", filter:"=", value: e.Seccion}],
      };
      setPolygons(null);

      CompromisosService.getSections(data_parameters_secc)
        .then((res) => {
          if (res.results) {
            setPolygons(res.response.data);
          } else {
            Swal.fire({ title: res.message, icon: "warning" });
          }
        })
        .catch((e) => {
          Swal.fire({ title: e.message, icon: "warning" });
        });
  };
  return (
    <AdminLayout>
      <Container maxWidth={false}>
        {/* <ActionHeader
        title="Mapas"
        handleclick={() => navigate("/map")}
      /> */}
        <Map
          data={polygons}
          sub_data={polygonsBlocks}
          CardComponentFlag={false}
          CardComponent={
            <></>
          }
          TitleCardComponent={""}
          CardInfoFlag={true}
          CardInfoInitialValues={{
            title: "INFORMACIÓN",
            elements: elements[polygons ? polygons.shp : "dfed"],
          }}
          handleClickViewDetailEvent={handleViewDetail}
          FilterComponentFlag={true}
          FilterComponent={FilterComponent}
          flagInfoGeneral={true}
          valuesInfoGeneral={valuesInfoGeneral}
          initialValuesInfoGeneral={{
            title: "RESUMEN",
            elements: [
              {
                title: "center",
                name: shpInfoGeneral,
                color: "#000",
                type: "text",
              },
              {
                title: "Meta:",
                name: "MetaCompromisos",
                color: "#000",
                type: "text",
              },
              {
                title: "Av Esp:",
                name: "AvanceEsperado",
                color: "#000",
                type: "text",
              },
              {
                title: "Av Comp:",
                name: "AvanceCompromisos",
                color: "#000",
                type: "text",
              },
              {
                title: "Av Comp Únicos:",
                name: "AvanceCompromisosUnicos",
                color: "#000",
                type: "text",
              },
              {
                title: "% Av Comp Únicos:",
                name: "PorcentajeAvanceCompromisosUnicos",
                color: "#000",
                type: "percent",
              },
              /* {
                title: "Total Man. P1:",
                name: "TotalP1",
                color: "#000",
                type: "text",
              },
              {
                title: "Total Man. P2:",
                name: "TotalP2",
                color: "#000",
                type: "text",
              },
              {
                title: "Total Man. P3:",
                name: "TotalP3",
                color: "#000",
                type: "text",
              }, */
            ],
          }}
        ></Map>
      </Container>
    </AdminLayout>
  );
};
export default Maps;
