import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Card, CardContent, Box, Grid, Typography } from '@mui/material';
import AdminLayout from '@components/MainPage/AdminLayout';
import ActionHeader from '@components/Containers/ActionHeader';
import UsersSearch from '@components/Users/UsersSearch';
import UserState from '@components/Users/UserStatus';
import SupportService from '@services/SupportServices'

const UserStatus = () => {
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [nombreCompleto, setNombreCompleto] = useState('');

  return (
    <AdminLayout>
      <Container maxWidth="false">
        <ActionHeader
          title="Bloqueos y Activaciones"
          isAction={true}
          titleButton="Regresar"
          iconButton="arrow_back"
          handleclick={() => navigate(-1)}
        />
        <Card className="card-primary">
          <CardContent>

            <Box sx={{ mt: 1, mb: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <UsersSearch
                    idUsuario={setId}
                    nombreCompleto={setNombreCompleto}
                    API={SupportService.getUser}
                  />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                  <Grid style={{ height: '50px', textAlign: 'center', marginBottom: '10px', fontStyle: 'italic', borderBottom: '2px solid #000' }}>
                    <Typography variant="h6" gutterBottom style={{ visibility: nombreCompleto ? 'visible' : 'hidden' }}>
                      {nombreCompleto}
                    </Typography>
                  </Grid>
                  <Card variant="outlined">
                    <CardContent>
                      <UserState
                        idUsuario={id}
                        API={SupportService.updateStatus}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </AdminLayout>
  );
};

export default UserStatus;
