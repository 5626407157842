import { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Icon,
  Card,
  CardContent,
  Typography,
  Alert,
} from "@mui/material";

import UserTable from "./UserTable";

import { limpiarTelefono } from "@utils/global";
import UserService from "@services/UserServices";
import Swal from "sweetalert2";
import { debounce } from "lodash";

const UserSearch = ({
  setIdUser,
  setName,
  setClearForm = () => {},
  label = "Encuentra al usuario y selecciona a quién le deseas actualizar la contraseña",
  labelNota = "Nota: Para realizar una búsqueda debe ingresar un nombre y al menos un apellido o por el contrario un número de celular.",
  API = UserService.getUser,
  clickCard = () => {},
}) => {
  const phoneRegex =
    /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/;

  const [searchData, setSearchData] = useState({
    nombre: "",
    numeroCelular: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setdata] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [allowPageSizeUpdate, setAllowPageSizeUpdate] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleSearch = async () => {
    setHasMore(true);
    const filtered = buildFilter();

    try {
      const result = await API({
        page,
        pageSize: 10,
        filtered,
      });
      const { results, message, response } = result;
      if (results) {
        setdata((prevData) => [...prevData, ...response.data]);
        if (response.total !== 0) {
          setTotal(response.total);
        }
      } else {
        throw new Error(message || "Error al realizar la búsqueda.");
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setHasMore(false);
    }
  };

  const buildFilter = () => {
    let filtered = [];

    if (searchData.nombre) {
      filtered = [
        ...filtered,
        {
          id: ["Nombre", "Paterno", "Materno"],
          filter: "LIKE",
          value: searchData.nombre,
        },
      ];
    }
    if (searchData.numeroCelular) {
      filtered = [
        ...filtered,
        {
          id: "Username",
          filter: "LIKE",
          value: searchData.numeroCelular,
        },
      ];
    }

    return filtered;
  };

  const handleValidate = () => {
    if (!searchData.nombre && !phoneRegex.test(searchData.numeroCelular)) {
      setErrorMessage("Debe ingresar al menos un criterio de búsqueda.");
    } else {
      setPage(0);
      setErrorMessage("");
      setdata([]);
      setTotal(0);
      setHasMore(false);
      handleSearch();
    }
  };

  const handleLimpiarClick = () => {
    clickCard();
    setPage(0);
    setSearchData({
      nombre: "",
      numeroCelular: "",
    });
    setErrorMessage("");
    setdata([]);
    setIdUser("");
    setName("");
    setTotal(0);
    setHasMore(false);
    setAllowPageSizeUpdate(false);
    setClearForm(true);
    setTimeout(() => {
      setAllowPageSizeUpdate(true);
    }, 500);
    setSelectedCard(null);
  };

  useEffect(() => {
    if (page !== 0 && data.length !== total) {
      handleSearch();
    }
    // eslint-disable-next-line
  }, [page]);

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      handleValidate();
    }
  };
  const debounceSearch = debounce(handleValidate, 1000);

  return (
    <Card variant="outlined" sx={{ borderRadius: 2 }} className="card-primary">
      <CardContent>
        <Typography variant="subtitle1" fontWeight={600}>
          {label}
        </Typography>
        <Alert
          variant="outlined"
          severity="info"
          sx={{ mt: 2, borderColor: "info.main" }}
        >
          {labelNota}
        </Alert>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          gap={1}
          mt={2}
        >
          <TextField
            label="Nombre"
            value={searchData.nombre.toUpperCase()}
            onChange={(e) =>
              setSearchData({ ...searchData, nombre: e.target.value })
            }
            variant="outlined"
            margin="none"
            size="small"
            fullWidth
            onKeyDown={(e) => {
              handleEnterPress(e);
            }}
            sx={{
              marginBottom: { xs: 1, sm: 0 },
              marginRight: { xs: 0, sm: 1 },
            }}
          />
          <TextField
            label="Número de Celular"
            value={searchData.numeroCelular}
            onChange={(e) =>
              setSearchData({
                ...searchData,
                numeroCelular: limpiarTelefono(e.target.value),
              })
            }
            variant="outlined"
            margin="none"
            size="small"
            fullWidth
            onKeyDown={(e) => {
              handleEnterPress(e);
            }}
          />
        </Box>
        <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
          <Button
            variant="contained"
            color="primaryDark"
            onClick={debounceSearch}
            size="small"
            startIcon={<Icon>search</Icon>}
          >
            BUSCAR
          </Button>
          <Button
            color="primaryDark"
            variant="outlined"
            onClick={handleLimpiarClick}
            size="small"
          >
            LIMPIAR
          </Button>
        </Box>
        {errorMessage && (
          <Box mt={2}>
            <Alert variant="outlined" severity="error">
              {errorMessage}
            </Alert>
          </Box>
        )}
        <Box sx={{ mt: 2 }}>
          <UserTable
            data={data}
            total={total}
            setIdUser={setIdUser}
            setName={setName}
            setClearForm={setClearForm}
            upPageSize={() => {
              if (allowPageSizeUpdate) {
                setPage(page + 1);
              }
            }}
            hasMore={hasMore}
            complement={true}
            clickCard={clickCard}
            setSelectedCard={setSelectedCard}
            selectedCard={selectedCard}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserSearch;
