import { useEffect, useState } from "react";
import {
  Container,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Stack,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import moment from "moment/moment";
import "moment/locale/es";

import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import SolidGauge from "@components/Reports/SolidGauge";
import TimeLine from "@components/Reports/TimeLine";
import TabsOptions from "@components/Reports/TabsOptions";
import Chart from "@components/Reports/Chart";
import Map from "@components/Reports/Map";
import PlacementColumns from "@components/Charts/DashChart";
import ReportsContext from "@components/Reports/ReportsContext"
import LoadingForm from "@components/LinearProgress/LoadingForm";
import report from "@services/ReportsServices";
import Swal from "sweetalert2";


const DashboardNational = () => {
  const [meta, setMeta] = useState([]);
  const [avance, setAvance] = useState([]);
  const [categories, setCategories] = useState([]);
  const [messages] = useState([
    { mensaje: "Top Mejores Estados - Compromisos", type: "" },
    { mensaje: "Top Peores Estados - Compromisos", type: "" },
  ]);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  //LoadigForm
  const [isLoadigForm, setIsLoadingForm] = useState(true);
  const [isSuccessFormSave] = useState(false);
  const [openLinearProgress, setOpenLinearProgress] = useState(true);
  const [totals, setTotals] = useState({
    Ejercicio: "",
    avance: 0,
    Meta: 0
  });
  const [bestTopList, setBestTopList] = useState([]);
  const [worstTopList, setWorstTopList] = useState([]);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      const type = tabValue + 1;
      await getTotals(type);
      await getBestTopTen(type);
      await getWorstTopTen(type);

      setLoading(false);
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
    }

    init();

  }, [tabValue]);

  const getTotals = async (type) => {
    try {
      const result = await report.getTotals({ type })
      const { results, response, message } = result;

      if (results) {
        const data = response.data;
        setTotals(data);
      } else {
        setTotals({
          Ejercicio: "",
          avance: 0,
          Meta: 0
        });
        Swal.fire({
          title: message,
          icon: 'warning'
        })
      }
    } catch (error) {
      setTotals({
        Ejercicio: "",
        avance: 0,
        Meta: 0
      });
      Swal.fire({
        title: error.message,
        icon: 'warning'
      });
    } finally {

    }
  }

  const getBestTopTen = async (type) => {
    try {
      const result = await report.getBestTopTen({ type })
      const { results, response, message } = result;

      if (results) {
        const data = response.data;
        setBestTopList(data);
      } else {
        setBestTopList([]);
        Swal.fire({
          title: message,
          icon: 'warning'
        })
      }
    } catch (error) {
      setBestTopList([]);
      Swal.fire({
        title: error.message,
        icon: 'warning'
      });
    } finally {

    }
  }

  const getWorstTopTen = async (type) => {
    try {
      const result = await report.getWorstTopTen({ type })
      const { results, response, message } = result;

      if (results) {
        const data = response.data;
        setWorstTopList(data);
      } else {
        setWorstTopList([]);
        Swal.fire({
          title: message,
          icon: 'warning'
        })
      }
    } catch (error) {
      setWorstTopList([]);
      Swal.fire({
        title: error.message,
        icon: 'warning'
      });
    } finally {

    }
  }

  const handleLoading = (loadingValue) => {
    setIsLoadingForm(loadingValue);
    setOpenLinearProgress(loadingValue);
  }

  return (
    <AdminLayout>
      <Container maxWidth={false}>
        <ActionHeader title="Reporte Nacional" />
        <LoadingForm
          loadinMessage={"Consultando..."}
          successMessage="¡Consultado con éxito!"
          isLoading={isLoadigForm}
          success={isSuccessFormSave}
          isOpen={openLinearProgress}
          setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
        />

        <ReportsContext.Provider value={{ setIsLoadingForm, setOpenLinearProgress, handleLoading }}>
          <Box>
            <Grid2 container spacing={2}>
              <Grid2 xs={12} md={3} lg={3}>
                <SolidGauge
                  max={parseInt(totals.Meta)}
                  data={parseInt(totals.avance)}
                  title={"Porcentaje de avance nacional"}
                />
              </Grid2>
              <Grid2 xs={12} md={9} lg={9}>
                <TimeLine />
              </Grid2>
            </Grid2>
          </Box>
          <TabsOptions
            tabValue={tabValue}
            setTabValue={setTabValue}
            setLoading={setLoading}
          />
          <Box>
            <Grid2 container spacing={2}>
              <Grid2 xs={12} md={4} lg={4}>
                <Stack direction={"column"}>
                  <Chart
                    vertical={bestTopList.map((item) => item.estado)}
                    horizontal={bestTopList.map((item) => item.value)}
                    message={messages[0]}
                    loading={loading}
                    graphType={1}
                  />
                  <Chart
                    vertical={worstTopList.map((item) => item.estado)}
                    horizontal={worstTopList.map((item) => item.value)}
                    message={messages[1]}
                    loading={loading}
                    graphType={3}
                  />
                </Stack>
              </Grid2>
              <Grid2 xs={12} md={8} lg={8}>
                <Map
                  best={bestTopList}
                  worst={worstTopList}
                />
              </Grid2>
            </Grid2>
          </Box>
          {/* <Box>
            <Card className="card-primary">
              <CardContent>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <PlacementColumns
                    titulo={{ title: "AVANCE" }}
                    subtitle={{
                      subtitle: moment().format('DD/MM/YYYY HH:mm')
                    }}
                    datos={{
                      categories: categories,
                      series: [
                        {
                          name: "Meta",
                          color: "rgba(0,65,165,1)",
                          data: meta,
                          pointPadding: 0.3,
                          pointPlacement: -0.2,
                        },
                        {
                          name: "Avance",
                          color: "rgba(147,220,236,.9)",
                          data: avance,
                          pointPadding: 0.4,
                          pointPlacement: -0.2,
                        },
                      ],
                    }}
                    loading={loading}
                  />
                )}
              </CardContent>
            </Card>
          </Box> */}
        </ReportsContext.Provider>
      </Container>
    </AdminLayout>
  );
};

export default DashboardNational;
