import PropTypes from 'prop-types';

// material-ui
import { Box,Card } from '@mui/material';


const MainCard = ({ children, ...other }) => (
  <Card
    sx={{
      maxWidth: { xs: 400, lg:2000 },
      margin: { xs: 2.5, md: 3 },
      '& > *': {
        flexGrow: 1,
        flexBasis: '50%'
      }
    }}
    {...other}
  >
    <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
  </Card>
);

MainCard.propTypes = {
  children: PropTypes.node
};

export default MainCard;
