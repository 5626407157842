import { useEffect, useState } from "react";

import { Button, Card, CardContent, Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import FilterCollapse from "@components/Filters/FilterCollapse";
import BasicSelect from "@components/Selects/BasicSelect";

import sije from "@services/SijeService";
import Swal from "sweetalert2";

const FilterPolygonal = ({ onChangeFilter, onClearFilter }) => {
  //CONSTANTS
  const catalogsOptions = [
    { id: "regiones" },
    { id: "municipios_reportes" },
    { id: "poligonos" },
  ];

  //States
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [catalogsFiltered, setCatalogFiltered] = useState(() => {
    let newObject = {};
    for (const item of catalogsOptions) {
      newObject[item.id] = [];
    }
    return newObject;
  });
  const [filterData, setFilterData] = useState({
    Region: "",
    Municipio: "",
    Poligono: "",
    Movilizacion: "",
  });

  const getCatalogs = async () => {
    try {
      setLoadingCatalogs((prevState) => !prevState);
      const result = await sije.getCatalogs({ catalogs: catalogsOptions });
      const { results, response, message } = await result;
      if (results) {
        setCatalogFiltered(response.catalogs);
        setCatalogs(response.catalogs);
      } else {
        throw message;
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingCatalogs((prevState) => !prevState);
    }
  };

  const handleChangeFilter = (e) => {
    const key = e.target.name;
    setFilterData({ ...filterData, [key]: e.target.value });
  };

  const handleChangeRegion = (e) => {
    const idRegion = e.target.value;
    setCatalogFiltered((prevState) => ({
      ...prevState,
      municipios_reportes: catalogs.municipios_reportes.filter(
        (item) => item.idRegion === idRegion
      ),
    }));
  };

  useEffect(() => {
    getCatalogs();
    // eslint-disable-next-line
  }, []);

  return (
    <Card className="card-primary">
      <CardContent>
        <FilterCollapse expand>
          <Grid2 container spacing={2}>
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="Region"
                label="Región"
                options={catalogsFiltered.regiones}
                value={filterData.Region}
                onChange={(e) => {
                  //handleChangeFilter(e);
                  handleChangeRegion(e);
                }}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="Municipio"
                label="Municipio"
                options={catalogsFiltered.municipios_reportes}
                value={filterData.Municipio}
                onChange={(e) => {
                  handleChangeFilter(e);
                }}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
            <Grid2 xs={12} md={3} lg={3}>
              <BasicSelect
                name="Poligono"
                label="Polígono"
                options={catalogsFiltered.poligonos}
                value={filterData.Poligono}
                onChange={(e) => {
                  handleChangeFilter(e);
                }}
                isLoading={loadingCatalogs}
                sx={{ width: "100%" }}
              />
            </Grid2>
            <Grid2 xs={12} md={3} lg={3}>
              <Stack direction={"row"} spacing={1}>
                <Button color="primaryDark">Limpiar</Button>
                <Button
                  variant="contained"
                  color="primaryDark"
                  //onClick={handleSearch}
                >
                  Filtrar
                </Button>
              </Stack>
            </Grid2>
          </Grid2>
        </FilterCollapse>
      </CardContent>
    </Card>
  );
};

export default FilterPolygonal;
